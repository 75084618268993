import { InvoiceList } from "./InvoiceList";
import { InvoiceCreate } from "./InvoiceCreate";
import { InvoiceEdit } from "./InvoiceEdit";

const Invoices = {
  list: InvoiceList,
  create: InvoiceCreate,
  edit: InvoiceEdit,
};

export { Invoices };
