import * as React from "react";
// import { makeStyles } from "@mui/styles";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import { Person, Business, Block, WorkOff } from "@mui/icons-material";
import {
  List as RaList,
  Link,
  SimpleListLoading,
  useListContext,
  useGetIdentity,
  useTranslate,
} from "react-admin";

import { EmployeeListFilter } from "./EmployeeListFilter";
import { EmployeeListActions } from "./EmployeeListActions";
import { TabPanel } from "../../Components";

const EmployeeListContent = () => {
  const { data, isLoading } = useListContext();

  if (isLoading || data === undefined) {
    return (
      <SimpleListLoading
        hasLeftAvatarOrIcon
        hasSecondaryText
        hasTertiaryText
        nbFakeLines={5}
      />
    );
  }

  return (
    <List>
      {data.map((employee) => {
        const id = employee["id"];
        const eid = encodeURIComponent(id);
        const name = `${employee.name.charAt(0)}. ${employee.lastName}`;
        const description = employee.jobTitle
          ? `${employee.jobTitle} | ${employee.phoneNumber} | ${employee.emailAddress}`
          : `${employee.phoneNumber} | ${employee.emailAddress}`;
        return employee.isActive ? (
          <React.Fragment>
            <ListItem
              key={id}
              component={Link}
              to={`/employees/${eid}/show`}
            >
              <ListItemAvatar>
                <Avatar>
                  {employee.isCompany ? <Business /> : <Person />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={name}
                secondary={description}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ) : null;
      })}
    </List>
  );
};

const InactiveEmployeeListContent = () => {
  const { data, isLoading } = useListContext();

  if (isLoading || data === undefined) {
    return (
      <SimpleListLoading
        hasLeftAvatarOrIcon
        hasSecondaryText
        hasTertiaryText
        nbFakeLines={5}
      />
    );
  }

  return (
    <List>
      {data.map((employee) => {
        const id = employee["id"];
        const eid = encodeURIComponent(id);
        const name = `${employee.name.charAt(0)}. ${employee.lastName}`;
        const description = employee.jobTitle
          ? `${employee.jobTitle} | ${employee.phoneNumber} | ${employee.emailAddress}`
          : `${employee.phoneNumber} | ${employee.emailAddress}`;
        return employee.isActive ? null : (
          <React.Fragment>
            <ListItem
              key={id}
              component={Link}
              to={`/employees/${eid}/show`}
            >
              <ListItemAvatar>
                <Avatar>{employee.isCompany ? <WorkOff /> : <Block />}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={name}
                secondary={description}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        );
      })}
    </List>
  );
};

const EmployeeList = ({
  filter = null,
  filters = null,
  actions = <EmployeeListActions />,
  aside = <EmployeeListFilter />,
  tabbed = true,
}) => {
  const translate = useTranslate();
  const { data: identity } = useGetIdentity();
  const [tabstate, setValue] = React.useState(0);
  const changeTab = (event, newValue) => {
    setValue(newValue);
  };

  return identity ? (
    <RaList
      empty={false}
      filter={filter}
      filters={filters}
      actions={actions}
      aside={aside}
      perPage={null}
      pagination={false}
      sx={{
        marginTop: 1,
      }}
    >
      {tabbed && identity.roles.some((e) =>
        ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
      ) ? (
        <React.Fragment>
          <Tabs
            value={tabstate}
            textColor="primary"
            indicatorColor="primary"
            onChange={changeTab}
            sx={{
              marginLeft: 1,
            }}
          >
            <Tab label={translate("employees.list.current")} />
            <Tab label={translate("employees.list.archived")} />
          </Tabs>
          <Divider />

          <TabPanel value={tabstate} index={0}>
            <EmployeeListContent />
          </TabPanel>

          <TabPanel value={tabstate} index={1}>
            <InactiveEmployeeListContent />
          </TabPanel>
        </React.Fragment>
      ) : (
        <EmployeeListContent />
      )}
    </RaList>
  ) : null;
};

export { EmployeeList };
