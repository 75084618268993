import * as React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Box,
  Paper,
  useMediaQuery,
} from "@mui/material";
import {
  List as RaList,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  TextField,
  useListContext,
  useGetIdentity,
  useRecordContext,
  DateField,
  Datagrid,
  Link,
  ShowButton,
  EditButton,
  ReferenceInput,
  SelectInput,
  Loading,
  Pagination,
  useLocaleState,
  useTranslate,
} from "react-admin";
import { nl } from "date-fns/locale";
import { format } from "date-fns";

import { EventReportShowContent } from "./EventReportShow";
import { EventReportListActions } from "./EventReportListActions";

const EventReportListContentExpand = () => {
  const record = useRecordContext();
  return (
    <Box>
      <Paper>
        <Box
          display="flex"
          maxWidth="98%"
          justifyContent="center"
          alignItems="center"
        >
          <EventReportShowContent record={record} />
        </Box>
      </Paper>
    </Box>
  );
};

const CustomEditButton = () => {
  const record = useRecordContext();
  const ownerId = record.owner.split("/")[3];
  let disabled = false;
  const { data: identity, isLoading: identityLoading } = useGetIdentity();

  if (identityLoading) {
    return null;
  }

  if (record.invoices.length > 0 || record.payrollItems.length > 0) {
    disabled = true;
  }

  if (
    ownerId === identity.id ||
    identity.roles.some((e) => ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e))
  ) {
    return <EditButton disabled={disabled} />;
  }

  return null;
};

const EventReportContentDataGrid = ({
  showOwner = true,
  showCustomer = true,
  showInvoices = true,
  allowSelect = true,
}) => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const [locale] = useLocaleState();
  const canShowInvoices =
    showInvoices &&
    identity.roles.some((e) => ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e))
      ? true
      : false;

        if (identityLoading) {
    return null;
  }

  return (
    <Datagrid
      rowClick="expand"
      expand={<EventReportListContentExpand />}
      expandSingle
      isRowSelectable={() => allowSelect}
    >
      {showOwner && (
        <ReferenceField
          label="employees.show.employee"
          source="owner"
          reference="employees"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
      )}
      {showCustomer && (
        <ReferenceField
          label="clients.show.client"
          source="customer"
          reference="customers"
          emptyText="None"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
      )}
      <DateField
        label="reporting.start"
        source="dateStart"
        locales={locale}
        showTime={true}
        options={{
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }}
      />
      <DateField
        label="reporting.end"
        source="dateEnd"
        locales={locale}
        showTime={true}
        options={{
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }}
      />
      <ReferenceField
        label="reporting.service"
        source="eventType"
        reference="event_types"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      {canShowInvoices && (
        <ReferenceArrayField
          label="reporting.invoices"
          source="invoices"
          reference="invoices"
          emptyText="None"
        >
          <SingleFieldList>
            <ChipField source={"invoiceNo"} />
          </SingleFieldList>
        </ReferenceArrayField>
      )}
      <ShowButton
        onClick={(e) => {
          e.preventDefault();
        }}
      />
      <CustomEditButton />
    </Datagrid>
  );
};

const EventReportContentList = ({ showOwner = true, showCustomer = true }) => {
  const translate = useTranslate();
  const { data, isLoading } = useListContext();

  if (isLoading) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  return (
    <List>
      {data.map((report) => {
        const id = report["id"];
        const eid = encodeURIComponent(id);
        const startDate = format(new Date(report.dateStart), "E dd LLL yyyy", {
          locale: nl,
        });
        const startTime = format(new Date(report.dateStart), "HH:mm");
        const endTime = format(new Date(report.dateEnd), "HH:mm");
        return (
          <ListItem
            button
            key={id}
            component={Link}
            to={`/event_reports/${eid}/show`}
          >
            <ListItemText
              primary={`${startDate}, ${startTime} - ${endTime}`}
              secondary={
                <React.Fragment>
                  <ReferenceField
                    record={report}
                    source="eventType"
                    reference="event_types"
                    link="show"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                  {report.customer && showCustomer && (
                    <React.Fragment>
                      {` ${translate("planning.representation.for")} `}
                      <ReferenceField
                        record={report}
                        source="customer"
                        reference="customers"
                        link="show"
                      >
                        <TextField source="fullName" />
                      </ReferenceField>
                    </React.Fragment>
                  )}
                  {showOwner && (
                    <React.Fragment>
                      {` ${translate("reporting.representation.reported_by")} `}
                      <ReferenceField
                        record={report}
                        source="owner"
                        reference="employees"
                        link="show"
                      >
                        <TextField source="fullName" />
                      </ReferenceField>
                    </React.Fragment>
                  )}
                </React.Fragment>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

const EventReportListContent = ({
  showOwner = true,
  showCustomer = true,
  showInvoices = true,
  allowSelect = true,
}) => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const isBig = useMediaQuery((theme) => theme.breakpoints.up("md"));

  if (identityLoading) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  return identity ? (
    <React.Fragment>
      {isBig ? (
        <EventReportContentDataGrid
          showOwner={showOwner}
          showCustomer={showCustomer}
          showInvoices={showInvoices}
          allowSelect={allowSelect}
        />
      ) : (
        <EventReportContentList
          showOwner={showOwner}
          showCustomer={showCustomer}
        />
      )}
    </React.Fragment>
  ) : null;
};

const EventReportList = ({
  sx = {},
  showOwner = true,
  showCustomer = true,
  showInvoices = true,
  filter = {},
  filters = [
    <ReferenceInput
      label="employees.show.employee"
      source="owner"
      reference="employees"
    >
      <SelectInput label="employees.show.employee" optionText="fullName" />
    </ReferenceInput>,
    <ReferenceInput
      label="clients.show.client"
      source="customer"
      reference="customers"
    >
      <SelectInput label="clients.show.client" optionText="fullName" />
    </ReferenceInput>,
  ],
  actions = <EventReportListActions />,
  disableSyncWithLocation = false,
  allowSelect = true,
}) => {
  const { data: identity } = useGetIdentity();
  return identity ? (
    <RaList
      filter={filter}
      filters={filters}
      empty={false}
      actions={actions}
      disableSyncWithLocation={disableSyncWithLocation}
      perPage={15}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 15, 30, 60, 100]} />}
      sort={{ field: "dateStart", order: "DESC" }}
      sx={sx}
    >
      <EventReportListContent
        showOwner={showOwner}
        showCustomer={showCustomer}
        showInvoices={showInvoices}
        allowSelect={allowSelect}
      />
    </RaList>
  ) : null;
};

export { EventReportList };
