import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  List as RaList,
  FunctionField,
  TextField,
  BooleanField,
  ArrayField,
  useListContext,
  useRecordContext,
  useGetIdentity,
  Datagrid,
  EditButton,
  ShowButton,
  Pagination,
  useTranslate,
} from "react-admin";

import { EventTypeListActions } from "./EventTypeListActions";

const useStyles = makeStyles((theme) => ({
  expandRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  expandCard: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
    marginLeft: 20,
    marginBottom: 10,
  },
  expandCardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tii: {
    "& .column-percentage": {
      width: "3ch",
      maxWidth: "3ch",
    },
  },
}));

const EventTypeListContentExpand = () => {
  const record = useRecordContext();
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Box className={classes.expandRoot}>
      {record.onCall ? (
        <Card className={classes.expandCard}>
          <CardContent className={classes.expandCardContent}>
            <Typography style={{ fontWeight: "bold" }} gutterBottom>
              {translate("configuration.services.on_call_active.title")}
            </Typography>
            <ArrayField
              record={record}
              source={"defaultOnCallRanges"}
            >
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{translate("configuration.services.on_call_active.start_day")}</TableCell>
                      <TableCell>{translate("configuration.services.on_call_active.start_time")}</TableCell>
                      <TableCell>{translate("configuration.services.on_call_active.end_day")}</TableCell>
                      <TableCell>{translate("configuration.services.on_call_active.end_time")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {record.defaultOnCallRanges.map((o) => (
                      <TableRow>
                        <TableCell>{o.startDay}</TableCell>
                        <TableCell>{o.startTime}</TableCell>
                        <TableCell>{o.endDay}</TableCell>
                        <TableCell>{o.endTime}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </ArrayField>
          </CardContent>
        </Card>
      ) : null}
      {record.rangeRates.length > 0 ? (
        <Card className={classes.expandCard}>
          <CardContent className={classes.expandCardContent}>
            <Typography style={{ fontWeight: "bold" }} gutterBottom>
              {translate("configuration.services.range_rates.title")}
            </Typography>
            <ArrayField
              record={record}
              source={"rangeRates"}
            >
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{translate("configuration.services.range_rates.range")}</TableCell>
                      <TableCell>{translate("configuration.services.range_rates.rate")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {record.rangeRates.map((o) => {
                      let arr = o.range.split(",");
                      let range = `${arr[0]} ${arr[1]} - ${arr[2]}`;
                      return (
                        <TableRow>
                          <TableCell>{range}</TableCell>
                          <TableCell>
                            {o.rate
                              ? parseFloat(o.rate).toLocaleString(
                                  "nl-NL",
                                  {
                                    style: "currency",
                                    currency: "EUR",
                                  }
                                )
                              : "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </ArrayField>
          </CardContent>
        </Card>
      ) : null}
      <Card className={classes.expandCard}>
        <CardContent className={classes.expandCardContent}>
          <Typography style={{ fontWeight: "bold" }} gutterBottom>
            {translate("configuration.services.template_invoice_lines.title")}
          </Typography>
          <ArrayField
            record={record}
            source={"templateInvoiceItems"}
            label={"Template Invoice Items"}
            className={classes.tii}
          >

            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{translate("configuration.services.template_invoice_lines.share")}</TableCell>
                    <TableCell>{translate("configuration.services.template_invoice_lines.template")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record.templateInvoiceItems.map((o) => (
                    <TableRow>
                      <TableCell>{`${o.percentage}%`}</TableCell>
                      <TableCell>{o.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ArrayField>
        </CardContent>
      </Card>
    </Box>
  );
};

const MaybeShowButton = () => {
  const record = useRecordContext();
  if (record.billable) {
    return (
      <ShowButton
        onClick={(e) => {
          e.preventDefault();
        }}
      />
    );
  }

  return null;
};

const EventTypeListContent = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { isLoading } = useListContext();
  const translate = useTranslate();

  if (isLoading || identityLoading) {
    return null;
  }

  return identity ? (
    <Datagrid
      rowClick="expand"
      expand={<EventTypeListContentExpand />}
      isRowExpandable={(record) => {
        return record.billable;
      }}
      expandSingle
    >
      <TextField source={"name"} label={translate("configuration.services.title")} />
      <BooleanField source={"billable"} label={translate("configuration.services.billable")} />
      <FunctionField
        source={"hourlyRate"}
        label={translate("configuration.services.hourly_rate")}
        render={(record) => {
          return record.billable
            ? parseFloat(record.hourlyRate).toLocaleString("nl-NL", {
                style: "currency",
                currency: "EUR",
              })
            : "-";
        }}
      />
      <FunctionField
        source={"VAT"}
        label={translate("configuration.services.VAT")}
        render={(record) => {
          return record.billable ? `${record.VAT}%` : "-";
        }}
      />
      <BooleanField source={"onCall"} label={translate("configuration.services.on_call")} />
      <BooleanField source={"global"} label={translate("configuration.services.global")} />
      <MaybeShowButton />
      <EditButton />
    </Datagrid>
  ) : null;
};

const EventTypeList = ({
  filter = null,
  filters = null,
  actions = <EventTypeListActions />,
}) => {
  const { data: identity } = useGetIdentity();
  return identity ? (
    <RaList
      empty={false}
      filter={filter}
      filters={filters}
      actions={actions}
      perPage={15}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 15, 30, 60, 100]} />}
      sort={{ field: "dateStart", order: "DESC" }}
    >
      <EventTypeListContent />
    </RaList>
  ) : null;
};

export { EventTypeList };
