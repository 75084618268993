import { PlannedEventList } from "./PlannedEventList";

/**
 * The PlannedEventList features an EventScheduler
 * which shows a calendar implementing all actions.
 */
const PlannedEvents = {
  list: PlannedEventList,
  show: false,
  create: false,
  edit: false,
};

export { PlannedEvents };
