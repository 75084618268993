const inMemoryJWTManager = () => {
  let inMemoryJWT = null;
  let isRefreshing = null;
  let logoutEventName = "ra-logout-crm-z";
  let refreshEndpoint = process.env.REACT_APP_TOKEN_REFRESH_URL;
  let refreshTimeOutId;

  const setLogoutEventName = (name) => (logoutEventName = name);

  // This countdown feature is used to renew the JWT before it's no longer valid
  // in a way that is transparent to the user.
  const scheduleTokenRefresh = (delay) => {
    refreshTimeOutId = window.setTimeout(
      getRefreshedToken,
      delay * 1000 - 5000
    ); // Validity period of the token in seconds, minus 5 seconds
  };

  const abortRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  const waitForTokenRefresh = () => {
    if (!isRefreshing) {
      return Promise.resolve();
    }
    return isRefreshing.then(() => {
      isRefreshing = null;
      return true;
    });
  };

  const getRefreshedToken = () => {
    /**
     * The refresh token is stored in a HttpOnly cookie
     * so we just need to hit the refreshEndpoint
     */
    const request = new Request(refreshEndpoint, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "include",
    });

    isRefreshing = fetch(request)
      .then((response) => {
        if (response.status !== 200) {
          eraseToken();
          global.console.warn("Token renewal failure");
          return { token: null };
        }
        return response.json();
      })
      .then(({ token }) => {
        if (token) {
          setToken(token, 300);
          return true;
        }
        eraseToken();
        return false;
      });

    return isRefreshing;
  };

  const getToken = () => inMemoryJWT;

  const setToken = (token, delay) => {
    inMemoryJWT = token;
    scheduleTokenRefresh(delay);
    return true;
  };

  const eraseToken = () => {
    inMemoryJWT = null;
    abortRefreshToken();
    window.localStorage.setItem(logoutEventName, Date.now().toString());
    return true;
  };

  const impersonateUser = (id) => {
    const request = new Request(
      `${process.env.REACT_APP_API_URL}/employees/${id}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        }),
        credentials: "include",
      }
    );

    fetch(request)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        if (data.username) {
          window.localStorage.setItem(
            "crm-impersonation",
            JSON.stringify({
              id: id,
              username: data.username,
              roles: data.roles,
              fullName: data.fullName,
            })
          );
          window.location.assign("/");
        }
      });
  };

  const getImpersonation = () => {
    let impersonation = JSON.parse(
      window.localStorage.getItem("crm-impersonation")
    );
    let impersonationId = impersonation?.id;
    if (impersonationId) {
      let impersonationUsername = impersonation.username;
      let impersonationRoles = impersonation.roles;
      let impersonationFullName = impersonation.fullName;
      return {
        impersonationId,
        impersonationUsername,
        impersonationRoles,
        impersonationFullName,
      };
    }
    return false;
  };

  const removeImpersonation = (refresh = true) => {
    window.localStorage.removeItem("crm-impersonation");
    if (refresh) {
      window.location.assign("/");
    }
  };

  // This listener will allow to disconnect a session of ra started in another tab
  window.addEventListener("storage", (event) => {
    if (event.key === logoutEventName) {
      inMemoryJWT = null;
    }
  });

  return {
    eraseToken,
    getRefreshedToken,
    getToken,
    setLogoutEventName,
    setToken,
    waitForTokenRefresh,
    impersonateUser,
    getImpersonation,
    removeImpersonation,
  };
};

export default inMemoryJWTManager();
