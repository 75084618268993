import { DefaultEditorOptions } from "ra-input-rich-text";
import { SnippetExtension } from "./Extensions"

const RichEditorOptions = {
  ...DefaultEditorOptions,
  extensions: [
    ...DefaultEditorOptions.extensions,
    SnippetExtension,
  ],
};

export { RichEditorOptions };
