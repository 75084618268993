/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { FilterLiveSearch } from "react-admin";
import { Box, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useCustomerListFilterStyles = makeStyles((theme) => ({
  root: {
    display: "none",
    marginLeft: "10px",
    marginRight: "24px",
    minWidth: "285px",
    width: "auto",
    [theme.breakpoints.up("lg")]: {
      display: "initial",
    },
  },
  sticky: {
    position: "sticky",
    marginTop: "48px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
}));

const CustomerListFilter = () => {
  const classes = useCustomerListFilterStyles();

  return (
    <Box className={classes.root} order="-1">
      <Paper className={classes.sticky}>
        <FilterLiveSearch source="q" />
      </Paper>
    </Box>
  );
};

export { CustomerListFilter };
