import * as React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  List as RaList,
  FunctionField,
  ReferenceField,
  TextField,
  ArrayField,
  useListContext,
  useRecordContext,
  useGetIdentity,
  Datagrid,
  ShowButton,
  ReferenceInput,
  SelectInput,
  DateField,
  BooleanField,
  Pagination,
  useLocaleState,
  useTranslate,
} from "react-admin";

import { PayrollItemListActions } from "./PayrollItemListActions";
import { timeConvert } from "../../Tools";

const useStyles = makeStyles((theme) => ({
  ii: {
    "& .column-minutes": {
      width: "4ch",
      maxWidth: "4ch",
    },
    "& .column-hrs": {
      width: "16ch",
      maxWidth: "16ch",
    },
  },
}));

const OldStylePayrollItemLines = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{translate("payroll.list.date")}</TableCell>
            <TableCell>{translate("invoices.list.description")}</TableCell>
            <TableCell>{translate("invoices.list.minutes")}</TableCell>
            <TableCell>{translate("invoices.list.hours")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.payrollItemLines.map((o) => (
            <TableRow>
              <TableCell>{o.date ? o.date : null}</TableCell>
              <TableCell>{o.description}</TableCell>
              <TableCell>
                {parseFloat(o.minutes).toLocaleString("nl-NL")}
              </TableCell>
              <TableCell>
                {`${timeConvert(o.minutes)[0]}:${timeConvert(o.minutes)[1]}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const NewStylePayrollItemLines = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{translate("configuration.services.on_call")}</TableCell>
            <TableCell>{translate("configuration.services.on_call_active.title")}</TableCell>
            <TableCell>{translate("invoices.list.description")}</TableCell>
            <TableCell>{translate("payroll.list.ORT")}</TableCell>
            <TableCell>{translate("payroll.list.flatFee")}</TableCell>
            <TableCell>{translate("invoices.list.hours")}</TableCell>
            <TableCell>{translate("payroll.grossTotal")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.payrollItemLines.map((o) => (
            <TableRow>
              <TableCell>
                <BooleanField record={o} source="onCall" />
              </TableCell>
              <TableCell>
                {o.onCall ? (<BooleanField record={o} source="onCallActive" />) : (<></>)}
              </TableCell>
              <TableCell>{o.description}</TableCell>
              <TableCell>
                {o.percentage && o.percentage !== "" ? `${o.percentage}%` : null}
              </TableCell>
              <TableCell>
                {o.flatRate && o.flatRate !== ""
                  ? parseFloat(o.flatRate).toLocaleString("nl-NL", {
                      style: "currency",
                      currency: "EUR",
                    })
                  : null}
              </TableCell>
              <TableCell>{`${timeConvert(o.minutes)[0]}:${
                timeConvert(o.minutes)[1]
              }`}</TableCell>
              <TableCell>
                {o.grossTotal
                  ? parseFloat(o.grossTotal).toLocaleString("nl-NL", {
                      style: "currency",
                      currency: "EUR",
                    })
                  : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PayrollItemListContentExpand = () => {
  const record = useRecordContext();
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center">
      <Paper>
        <ArrayField
          record={record}
          source={"payrollItemLines"}
          className={classes.ii}
        >
          {record.payrollItemLines[0].date && record.payrollItemLines[0].date !== "" ? (
            <OldStylePayrollItemLines />
          ) : (
            <NewStylePayrollItemLines />
          )}
        </ArrayField>
      </Paper>
    </Box>
  );
};

const PayrollItemListContent = ({ showEmployee = true }) => {
  const [locale] = useLocaleState();
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { isLoading } = useListContext();

  if (isLoading || identityLoading) {
    return null;
  }

  return identity ? (
    <Datagrid
      rowClick="expand"
      expand={<PayrollItemListContentExpand />}
      expandSingle
    >
      {showEmployee && (
        <ReferenceField label="employees.show.employee" reference="employees" source={"employee"}>
          <TextField source={"fullName"} />
        </ReferenceField>
      )}
      <TextField source={"payrollPeriod"} label={"payroll.period"} />
      <FunctionField
        source={"totalDays"}
        label={"invoices.totalDays"}
        render={(record) => {
          return parseFloat(record.totalDays).toLocaleString("nl-NL");
        }}
      />
      <FunctionField
        source={"totalMinutes"}
        label={"invoices.totalHours"}
        render={(record) => {
          return `${timeConvert(record.totalMinutes)[0]}:${
            timeConvert(record.totalMinutes)[1]
          }`;
        }}
      />
      <FunctionField
        source={"grossTotal"}
        label={"payroll.grossTotal"}
        render={(record) => {
          return record.grossTotal
            ? parseFloat(record.grossTotal).toLocaleString("nl-NL", {
                style: "currency",
                currency: "EUR",
              })
            : null
        }}
      />
      {/* {(identity.roles.some((e) =>
          ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
        ) && (
          <FunctionField
            source={"costTotal"}
            label={"Total cost"}
            render={(record) => {
              return record.costTotal
                ? parseFloat(record.costTotal).toLocaleString("nl-NL", {
                    style: "currency",
                    currency: "EUR",
                  })
                : null;
            }}
          />
        )
      )} */}
      <DateField
        source="createdAt"
        locales={locale}
        showTime={true}
        options={{
          dateStyle: "medium",
          timeStyle: "short",
          hour12: false,
        }}
      />
      <ShowButton
        onClick={(e) => {
          e.preventDefault();
        }}
      />
    </Datagrid>
  ) : null;
};

const PayrollItemList = ({
  showEmployee = true,
  filter = null,
  filters = [
    <ReferenceInput
      label="Employee(s)"
      source="employee"
      reference="employees"
      filter={{ isActive: true }}
    >
      <SelectInput optionText="fullName" />
    </ReferenceInput>,
  ],
  actions = <PayrollItemListActions />,
}) => {
  const { data: identity } = useGetIdentity();
  return identity ? (
    <RaList
      filter={filter}
      filters={filters}
      empty={false}
      actions={actions}
      perPage={15}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 15, 30, 60, 100]} />}
      sort={{ field: "payrollPeriod", order: "DESC" }}
    >
      <PayrollItemListContent showEmployee={showEmployee} />
    </RaList>
  ) : null;
};

export { PayrollItemList, PayrollItemListContent };
