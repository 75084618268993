import { EventTypeList } from "./EventTypeList";
import { EventTypeCreate } from "./EventTypeCreate";
import { EventTypeEdit } from "./EventTypeEdit";

const EventTypes = {
  list: EventTypeList,
  show: null,
  create: EventTypeCreate,
  edit: EventTypeEdit,
};

export { EventTypes };
