import * as React from "react";
import {
  List as RaList,
  ReferenceField,
  TextField,
  FunctionField,
  useGetIdentity,
  useGetList,
  Datagrid,
  EditButton,
  useRecordContext,
  ArrayField,
  Pagination,
  useTranslate,
} from "react-admin";

import { CustomerParameterListActions } from "./CustomerParameterListActions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function isNumeric(str) {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

const CustomerParameterValue = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  if (isNumeric(record.value)) {
    return (
      <FunctionField
        record={record}
        source={"value"}
        render={(record) => {
          return parseFloat(record.value).toLocaleString("nl-NL", {
            style: "currency",
            currency: "EUR",
          });
        }}
      />
    );
  } else {
    if (!Array.isArray(record.value)) {
      record.value = JSON.parse(record.value);
    }
    return (
      <ArrayField record={record} source={"value"}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translate("configuration.services.range_rates.range")}</TableCell>
                <TableCell>{translate("configuration.services.range_rates.rate")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record.value.map((o) => {
                let arr = o.range.split(",");
                return (
                  <TableRow>
                    <TableCell>{`${arr[0]} ${arr[1]} - ${arr[2]}`}</TableCell>
                    <TableCell>
                      {o.rate
                        ? parseFloat(o.rate).toLocaleString("nl-NL", {
                            style: "currency",
                            currency: "EUR",
                          })
                        : "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ArrayField>
    );
  }
};

const CustomerParameterListContent = ({ showCustomer = true }) => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { data, isLoading } = useGetList("event_types", {
    pagination: { pagination: false },
    filter: { pagination: false },
  });
  const eventTypesByTag = {};

  if (isLoading || identityLoading) {
    return null;
  }

  data.map((eventType) => {
    eventTypesByTag[eventType.tag] = eventType;
    return "";
  });

  return identity ? (
    <Datagrid>
      {showCustomer && (
        <ReferenceField label="clients.show.client" source="customer" reference="customers" link="show">
          <TextField source="fullName" />
        </ReferenceField>
      )}
      <FunctionField
        source={"path"}
        label={"planning.add.service"}
        render={(record) => {
          const tag = record.path.split("/")[2];
          const eventType = eventTypesByTag[tag];
          const value = `${eventType.name} [${tag}]`;
          return value;
        }}
      />
      <CustomerParameterValue label="clients.custom_pricing.custom_price" />
      <EditButton />
    </Datagrid>
  ) : null;
};

const CustomerParameterList = ({
  sx = {},
  showCustomer = true,
  filter = null,
  filters = null,
  actions = <CustomerParameterListActions />,
}) => {
  const { data: identity } = useGetIdentity();
  return identity ? (
    <RaList
      sx={sx}
      empty={false}
      filter={filter}
      filters={filters}
      actions={actions}
      perPage={15}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 15, 30, 60, 100]} />}
      sort={{ field: "value", order: "DESC" }}
    >
      <CustomerParameterListContent showCustomer={showCustomer} />
    </RaList>
  ) : null;
};

export { CustomerParameterList };
