import * as React from "react";

import { Navigate, Route } from "react-router-dom";
import { useIntrospection } from "@api-platform/admin";

import { Resource, CustomRoutes } from "react-admin";
import { HydraAdmin, hydraSchemaAnalyzer } from "@api-platform/admin";

import { CustomLayout, LoginPage, LightTheme, DarkTheme } from "./Layout";
import {
  dataProvider,
  authProvider,
  i18nProvider,
  inMemoryJWT,
} from "./Providers";

import { Dashboard, Configuration } from "./Components/Pages";
import {
  Customers,
  CustomerParameters,
  Employees,
  PlannedEvents,
  EventTypes,
  EventReports,
  Invoices,
  PayrollItems,
  PayrollCollections,
  InvoiceCollections,
  ProjectionInvoiceCollections,
  ProjectionPayrollCollections,
} from "./Resources";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { nl } from 'date-fns/locale'

const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (inMemoryJWT.getToken()) {
    introspect();
    return <></>;
  }

  return <Navigate to="/login" />;
};

const App = () => {
  const [redirectToLogin, setRedirectToLogin] = React.useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
      <HydraAdmin
        i18nProvider={i18nProvider}
        authProvider={authProvider}
        dataProvider={dataProvider(setRedirectToLogin)}
        schemaAnalyzer={hydraSchemaAnalyzer()}
        entrypoint={process.env.REACT_APP_API_URL}
        layout={CustomLayout}
        loginPage={LoginPage}
        lightTheme={LightTheme}
        darkTheme={DarkTheme}
        theme={LightTheme}
        dashboard={Dashboard}
        disableTelemetry
        requireAuth
      >
        {redirectToLogin ? (
          <CustomRoutes>
            <Route path="/" element={<RedirectToLogin />} />
            <Route path="/:path" element={<RedirectToLogin />} />
          </CustomRoutes>
        ) : (
          <>
            <CustomRoutes>
              <Route path="/configuration" element={<Configuration />} />
            </CustomRoutes>

            <Resource name="customers" {...Customers} />
            <Resource name="customer_parameters" {...CustomerParameters} />
            <Resource name="employees" {...Employees} />

            <Resource name="planned_events" {...PlannedEvents} />
            <Resource name="event_reports" {...EventReports} />
            <Resource name="event_types" {...EventTypes} />

            <Resource name="invoices" {...Invoices} />
            <Resource name="payroll_items" {...PayrollItems} />
            <Resource name="payroll_collections" {...PayrollCollections} />
            <Resource name="invoice_collections" {...InvoiceCollections} />

            <Resource name="projection_invoices" {...Invoices} />
            <Resource name="projection_payroll_items" {...PayrollItems} />
            <Resource
              name="projection_payroll_collections"
              {...ProjectionPayrollCollections}
            />
            <Resource
              name="projection_invoice_collections"
              {...ProjectionInvoiceCollections}
            />
          </>
        )}
      </HydraAdmin>
    </LocalizationProvider>
  );
};

export default App;
