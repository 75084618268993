import * as React from "react";
import { makeStyles } from "@mui/styles";
import {
  TopToolbar,
  FilterButton,
  ExportButton,
  CreateButton,
  useGetIdentity,
  LinearProgress,
} from "react-admin";

const useEventReportListActionsStyles = makeStyles((theme) => ({
  createButton: {
    marginLeft: theme.spacing(2),
  },
}));

const EventReportListActions = ({
  showFilter = true,
  showExport = true,
  showCreate = true,
}) => {
  const { data: identity, isLoading } = useGetIdentity();
  const classes = useEventReportListActionsStyles();

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <TopToolbar>
      {showFilter && <FilterButton />}
      {showExport &&
        identity.roles.some((e) =>
          ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
        ) && <ExportButton />}
      {showCreate && (
        <CreateButton
          className={classes.createButton}
          basePath="/event_reports"
          variant="contained"
          label="reporting.add"
        />
      )}
    </TopToolbar>
  );
};

export { EventReportListActions };
