import * as React from "react";
import { Box, Paper } from "@mui/material";
import {
  List as RaList,
  FunctionField,
  TextField,
  useListContext,
  useGetIdentity,
  Datagrid,
  ShowButton,
  Pagination,
  ReferenceArrayField,
} from "react-admin";

import { InvoiceCollectionListActions } from "../InvoiceCollections/InvoiceCollectionListActions";
import { timeConvert } from "../../Tools";
import { InvoiceListContent } from "../Invoices/InvoiceList";

const ProjectionInvoiceCollectionListContentExpand = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Paper>
        <ReferenceArrayField reference="projection_invoices" source={"invoices"}>
          <InvoiceListContent />
        </ReferenceArrayField>
      </Paper>
    </Box>
  );
};

const ProjectionInvoiceCollectionListContent = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { isLoading } = useListContext();

  if (isLoading || identityLoading) {
    return null;
  }

  return identity ? (
    <Datagrid
      isRowSelectable={false}
      rowClick="expand"
      expand={<ProjectionInvoiceCollectionListContentExpand />}
      expandSingle
    >
      <TextField source={"invoicePeriod"} label={"Period"} />
      <FunctionField
        source={"totalDays"}
        label={"Total days"}
        render={(record) => {
          return parseFloat(record.totalDays).toLocaleString("nl-NL");
        }}
      />
      <FunctionField
        source={"totalMinutes"}
        label={"Total minutes"}
        render={(record) => {
          return parseFloat(record.totalMinutes).toLocaleString("nl-NL");
        }}
      />
      <FunctionField
        source={"totalMinutes"}
        label={"Total hours"}
        render={(record) => {
          return `${timeConvert(record.totalMinutes)[0]}:${
            timeConvert(record.totalMinutes)[1]
          }`;
        }}
      />
      <FunctionField
        source={"totalDue"}
        label={"Total Due"}
        render={(record) => {
          return record.totalDue
            ? parseFloat(record.totalDue).toLocaleString("nl-NL", {
                style: "currency",
                currency: "EUR",
              })
            : null;
        }}
      />
      <ShowButton
        onClick={(e) => {
          e.preventDefault();
        }}
      />
    </Datagrid>
  ) : null;
};

const ProjectionInvoiceCollectionList = ({
  filter = null,
  filters = [],
  actions = <InvoiceCollectionListActions />,
}) => {
  const { data: identity } = useGetIdentity();
  return identity ? (
    <RaList
      filter={filter}
      filters={filters}
      empty={false}
      actions={actions}
      perPage={15}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 15, 30, 60, 100]} />}
      sort={{ field: "invoicePeriod", order: "DESC" }}
    >
      <ProjectionInvoiceCollectionListContent />
    </RaList>
  ) : null;
};

export { ProjectionInvoiceCollectionList };
