import * as React from "react";
import {
  EditBase,
  TextInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  Form,
  FormDataConsumer,
  Toolbar,
  required,
  useEditContext,
  useUpdate,
  useNotify,
  useRedirect,
  DateInput,
  useTranslate,
} from "react-admin";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Person, Business, Receipt } from "@mui/icons-material";
import { AddressForm } from "../../Components";

const Spacer = () => <Box width={20} component="span" />;

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    width: "60px",
    height: "60px",
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
  formCard: {
    width: "100%",
  },
}));

const transformValues = async (values) => {
  values.weeklyHours = values.weeklyHours?.toString() ?? undefined;
  values.monthlyRate = values.isPaidMonthly
    ? values.monthlyRate.toString()
    : undefined;
  values.hourlyRate = values.isPaidMonthly
    ? undefined
    : values.hourlyRate?.toString();
  values.hourlyCost =
    (values.isPaidMonthly ? undefined : values.hourlyCost?.toString()) ??
    undefined;
  return values;
};

const RepresentativeForm = () => {
  const translate = useTranslate();
  return (
    <>
      <Box mt={2} display="flex" justifyContent="center">
        <Typography>{translate("contact.representative")}</Typography>
      </Box>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <Box display="flex">
            <TextInput
              source="representativeName"
              validate={formData.isCompany ? [required()] : null}
              fullWidth
            />
            <Spacer />
            <TextInput
              source="representativeEmailAddress"
              validate={formData.isCompany ? [required()] : null}
              fullWidth
            />
          </Box>
        )}
      </FormDataConsumer>
      <Box display="flex">
        <TextInput source="representativePhoneNumber" fullWidth />
      </Box>
    </>
  );
};

const EmployeeEditContent = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const { record } = useEditContext();
  const [update] = useUpdate();
  const save = React.useCallback(
    async (values) => {
      await transformValues(values);
      try {
        await update(
          "employees",
          { id: record ? record.id : null, data: values },
          { returnPromise: true }
        ).then((response) => {
          const eid = encodeURIComponent(response.id);
          redirect(`/employees/${eid}/show`);
        });
      } catch (error) {
        let errors = {};
        error.body[0]["http://www.w3.org/ns/hydra/core#description"][0][
          "@value"
        ]
          .split(/\n/)
          .forEach((e) => {
            notify(e, { type: "warning" });
            const err = e.split(": ");
            errors[err[0]] = err[1];
          });
        notify("ra.notification.edit.failure", {
          type: "warning",
        });
        return errors;
      }
    },
    [update, notify, redirect, record]
  );
  const classes = useStyles();
  return record ? (
    <Box
      sx={{
        display: "flex",
        marginTop: 2,
        width: "100%",
        maxWidth: 1620,
        alignSelf: "center",
        "& form": {
          width: "100%",
        },
      }}
    >
      <Form record={record} onSubmit={save}>
        <Card className={classes.formCard}>
          <CardContent sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                width: "100%",
                maxWidth: 800,
                paddingRight: "10px",
                borderRight: "1px dashed",
              }}
            >
              <Box display="flex">
                <Box flex={1}></Box>
                <Box flex={1} display="flex" justifyContent="center">
                  <FormDataConsumer>
                    {({ formData, ...rest }) => (
                      <Avatar className={classes.avatarContainer}>
                        {formData.isCompany ? (
                          <Business className={classes.avatar} />
                        ) : (
                          <Person className={classes.avatar} />
                        )}
                      </Avatar>
                    )}
                  </FormDataConsumer>
                </Box>
                <Box flex={1} display="flex" justifyContent="flex-end">
                  <React.Fragment>
                    <BooleanInput label="Active" source="isActive" />
                    <BooleanInput
                      label="B2B"
                      source="isCompany"
                      disabled
                      aria-disabled
                      aria-readonly
                    />
                  </React.Fragment>
                </Box>
              </Box>
              <Box mt={2} display="flex" justifyContent="center">
                <Typography>
                  {translate("employees.edit.personal_information")}
                </Typography>
              </Box>
              <Box display="flex">
                <TextInput
                  label="First name"
                  source="name"
                  helperText="Preferred first name."
                  validate={[required()]}
                  fullWidth
                />
                <Spacer />
                <TextInput
                  label="Last name"
                  source="lastName"
                  helperText="Legally recognized last name."
                  validate={[required()]}
                  fullWidth
                />
              </Box>
              <TextInput
                label="Legal name(s)"
                source="legalNames"
                helperText="Legally recognized name(s), if it differs."
                fullWidth
              />
              <Box>
                <Box display="flex">
                  <TextInput
                    label="Primary email"
                    source="emailAddress"
                    helperText="Primary email address."
                    validate={[required()]}
                    fullWidth
                  />
                  <Spacer />
                  <TextInput
                    label="Payslip email"
                    source="payslipEmailAddress"
                    helperText="Copies of payslips will be sent here."
                    fullWidth
                  />
                </Box>
                <Box display="flex">
                  <TextInput
                    label="Phone number"
                    source="phoneNumber"
                    helperText="Primary phone number."
                    validate={[required()]}
                    fullWidth
                  />
                  <Spacer />
                  <FormDataConsumer>
                    {({ formData, ...rest }) => (
                      <TextInput
                        label={formData.isCompany ? "KVK" : "BSN"}
                        source="socialRegistrationNumber"
                        helperText="Social registration number."
                        validate={[required()]}
                        fullWidth
                        {...rest}
                      />
                    )}
                  </FormDataConsumer>
                </Box>
                <Box display="flex">
                  <DateInput
                    label="DOB"
                    source="DOB"
                    helperText="Date of birth."
                    options={{ format: "dd/MM/yyyy" }}
                    validate={[required()]}
                    fullWidth
                  />
                  <Spacer />
                  <TextInput
                    label="IBAN"
                    source="bankAccount"
                    helperText="International bank account number."
                    validate={[required()]}
                    fullWidth
                  />
                </Box>
                <Divider />
                <AddressForm />
                <RepresentativeForm />
                <Divider />
              </Box>
            </Box>
            <Box sx={{ width: "100%", maxWidth: 800, paddingLeft: "10px" }}>
              <Box display="flex">
                <Box flex={1}></Box>
                <Box flex={1} display="flex" justifyContent="center">
                  <Avatar className={classes.avatarContainer}>
                    <Receipt className={classes.avatar} />
                  </Avatar>
                </Box>
                <Box flex={1} display="flex" justifyContent="flex-end">
                  <React.Fragment>
                    <BooleanInput label="Paid Monthly" source="isPaidMonthly" />
                  </React.Fragment>
                </Box>
              </Box>
              <Box mt={2} display="flex" justifyContent="center">
                <Typography>
                  {translate("employees.edit.contract_details")}
                </Typography>
              </Box>
              <Box display="flex">
                <TextInput
                  label="Job Title"
                  source="jobTitle"
                  helperText="The Employee's job title"
                  fullWidth
                />
                <Spacer />
                <TextInput
                  label="Salary Scale"
                  source="salaryScale"
                  helperText="The designated FWG scale."
                  fullWidth
                />
              </Box>
              <Box display="flex">
                <NumberInput
                  min={"0.00"}
                  step={"0.01"}
                  label="Weekly Hours"
                  source="weeklyHours"
                  helperText="The amount of weekly hours on contract."
                  fullWidth
                />
                <Spacer />
                <FormDataConsumer>
                  {({ formData, ...rest }) => (
                    <React.Fragment>
                      {formData.isPaidMonthly ? (
                        <NumberInput
                          min={"0.00"}
                          step={"0.01"}
                          label={"Monthly Salary"}
                          source={"monthlyRate"}
                          helperText={"The contracted monthly salary"}
                          fullWidth
                        />
                      ) : (
                        <NumberInput
                          min={"0.00"}
                          step={"0.01"}
                          label={"Hourly Rate"}
                          source={"hourlyRate"}
                          helperText={"The contracted hourly rate."}
                          fullWidth
                        />
                      )}
                    </React.Fragment>
                  )}
                </FormDataConsumer>
                <Spacer />
                <NumberInput
                  min={"0.00"}
                  step={"0.01"}
                  label={"Hourly Cost"}
                  source={"hourlyCost"}
                  helperText={"The total cost per hour as given by AKVB."}
                  fullWidth
                />
              </Box>
              <Box display="flex">
                <DateInput
                  label="Start Date"
                  source="startDate"
                  helperText="The date the contract starts."
                  options={{ format: "dd/MM/yyyy" }}
                  fullWidth
                />
                <Spacer />
                <DateInput
                  label="End Date"
                  source="endDate"
                  helperText="The date the contract ends."
                  options={{ format: "dd/MM/yyyy" }}
                  fullWidth
                />
              </Box>
              <Divider />
              <Box mt={2} display="flex" justifyContent="center">
                <Typography>{translate("employees.show.clients")}</Typography>
              </Box>
              <Box>
                <ReferenceArrayInput source="customers" reference="customers">
                  <SelectArrayInput
                    optionText="fullName"
                    helperText="This employee's related customers."
                    translateChoice={false}
                    fullWidth
                  />
                </ReferenceArrayInput>
              </Box>
            </Box>
          </CardContent>
          <Toolbar />
        </Card>
      </Form>
    </Box>
  ) : null;
};

const EmployeeEdit = () => {
  return (
    <EditBase
      mutationMode="pessimistic"
      transform={(data) => ({
        ...data,
      })}
    >
      <EmployeeEditContent />
    </EditBase>
  );
};

export { EmployeeEdit };
