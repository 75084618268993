import * as React from "react";
import {
  List as RaList,
  Loading,
  useListContext,
  useGetIdentity,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

import { PlannedEventListActions } from "./PlannedEventListActions";
import { EventScheduler } from "../../Components";
import { EventDataTransformer } from "../../Tools";

const PlannedEventList = ({
  filter = null,
  filters = [
    <ReferenceArrayInput
      label="planning.add.employees"
      source="employees"
      reference="employees"
      filter={{ isActive: true }}
    >
      <SelectArrayInput label="planning.add.employees" optionText="fullName" />
    </ReferenceArrayInput>,
    <ReferenceInput
      label="planning.add.client"
      source="customer"
      reference="customers"
      filter={{ isActive: true }}
    >
      <SelectInput label="planning.add.client" optionText="fullName" />
    </ReferenceInput>,
  ],
  actions = <PlannedEventListActions />,
  mainResourceName = "customer",
  height = undefined,
  defaultView = undefined,
  allowEdits = true,
  disableSyncWithLocation = false,
}) => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();

  if (identityLoading) {
    return <Loading />;
  }

  return identity ? (
    <RaList
      filter={filter}
      filters={filters}
      actions={actions}
      disableSyncWithLocation={disableSyncWithLocation}
      perPage={null}
      pagination={false}
      empty={false}
      sx={{
        height: "100%",
        width: "100%",
        "& .RaList-content": {
          height: "100%",
          width: "100%",
        },
      }}
    >
      <PlannedEventListContent
        height={height}
        defaultView={defaultView}
        allowEdits={allowEdits}
        mainResourceName={mainResourceName}
      />
    </RaList>
  ) : null;
};

const PlannedEventListContent = (props) => {
  const { data: eventData, isLoading: eventsLoading } = useListContext();

  if (eventsLoading || !eventData) {
    return <Loading />;
  }

  const plannedEvents = eventData.map((plannedEvent) => {
    return EventDataTransformer.fromPlannedEvent(plannedEvent);
  });

  return (
    <EventScheduler
      height={props.height}
      defaultView={props.defaultView}
      allowEdits={props.allowEdits}
      mainResourceName={props.mainResourceName}
      appointments={plannedEvents}
    />
  );
};

export { PlannedEventList };
