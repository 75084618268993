import * as React from "react";
import {
  Grid,
  Divider,
  Typography,
  Avatar,
  Tabs,
  Tab,
  Box,
  Button,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Person, Business, SwapHoriz } from "@mui/icons-material";
import {
  TextField,
  FunctionField,
  Show,
  SimpleShowLayout,
  useGetIdentity,
  useShowContext,
  Loading,
  ResourceContextProvider,
  useRecordContext,
  ReferenceInput,
  SelectInput,
  useTranslate,
  LinearProgress,
} from "react-admin";

import { ShowGuesser } from "@api-platform/admin";
import { CustomerList } from "../Customers/CustomerList";
import { EventReportList } from "../EventReports/EventReportList";
import { PayrollItemList } from "../PayrollItems/PayrollItemList";
import { EventScheduler, TabPanel } from "../../Components";
import inMemoryJWT from "../../Providers/inMemoryJWT";
import { PlannedEventList } from "../PlannedEvents/PlannedEventList";
import { EventReportListActions } from "../EventReports/EventReportListActions";

const useAsideFilterStyles = makeStyles((theme) => ({
  root: {
    display: "none",
    order: -1,
    minWidth: "352px",
    marginLeft: "10px",
    marginRight: "24px",
    "& .RaShow-noActions": {
      marginTop: 0,
      position: "sticky",
      top: "65px",
    },
    [theme.breakpoints.up("lg")]: {
      display: "initial",
    },
  },
  avatarContainer: {
    width: "60px",
    height: "60px",
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      flexDirection: "row",
      "& button": {
        flex: 1,
      },
    },
  },
}));

const showEmployeeContractStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    marginTop: 50,
    display: "flex",
  },
  card: {
    minHeight: "75px",
    minWidth: "210px",
    marginLeft: 25,
  },
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardContentTitle: {},
  cardContentBox: {
    height: "100%",
    display: "flex",
  },
  cardContentValue: {
    margin: "auto",
  },
}));

const ImpersonationButton = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const record = useRecordContext();
  const eid = record?.id.split("/")[3];
  const translate = useTranslate();

  if (
    !record ||
    identityLoading ||
    inMemoryJWT.getImpersonation() ||
    identity.id === eid
  ) {
    return null;
  }

  if (inMemoryJWT.getImpersonation() || identity.id === eid) {
    return null;
  }

  return (
    identity.roles.some((e) => ["ROLE_ADMIN"].includes(e)) && (
      <Button
        variant="text"
        endIcon={<SwapHoriz />}
        style={{ alignSelf: "flex-end" }}
        onClick={() => {
          inMemoryJWT.impersonateUser(eid);
        }}
      >
        {translate("impersonation.start")}
      </Button>
    )
  );
};

/**
 * Left bar area
 */
const Aside = () => {
  const translate = useTranslate();
  const classes = useAsideFilterStyles();
  const [tabstate, setValue] = React.useState(0);
  const record = useRecordContext();

  if (!record) {
    return (
      <Box mt={10} mb={5} className={classes.root}>
        <LinearProgress />
      </Box>
    );
  }

  const changeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Show actions={false} className={classes.root}>
      <CardContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <ImpersonationButton />
          <Avatar className={classes.avatarContainer}>
            <FunctionField
              render={(record) => {
                return record?.isCompany ? (
                  <Business className={classes.avatar} />
                ) : (
                  <Person className={classes.avatar} />
                );
              }}
            />
          </Avatar>
          <Box mt={1}>
            <Typography align="center">
              <Typography variant="h5">{record?.name.charAt(0)}. {record?.lastName}</Typography>
              <Typography variant="h7">{record?.jobTitle}</Typography>
            </Typography>
          </Box>
        </Grid>
        <br />

        <Divider />
        <Tabs
          value={tabstate}
          textColor="primary"
          indicatorColor="primary"
          onChange={changeTab}
          className={classes.tabs}
        >
          <Tab label={translate("contact.details")} />
          <Tab disabled={!record?.address?.streetName} label={translate("contact.address")} />
        </Tabs>

        <TabPanel value={tabstate} index={0}>
            <Show actions={false}>
            <SimpleShowLayout>
              {record?.representativeName ? (
                <Typography>{translate("employees.show.employee")}</Typography>
              ) : null }
              <TextField source={"name"} label={translate("contact.name")} />
              <TextField source={"lastName"} label={translate("contact.lastName")} />
              {record?.legalNames ? (
                <TextField source={"legalFullName"} label={translate("contact.legalName")} />
              ) : null }
              {record?.emailAddress ? (
                <TextField source={"emailAddress"} label={translate("contact.email_address")} />
              ) : null}
              {record?.phoneNumber ? (
                <TextField source={"phoneNumber"} label={translate("contact.phone_number")} />
              ) : null}
              {record?.socialRegistrationNumber ? (
                <TextField source={"socialRegistrationNumber"} label={translate("contact.social_registration_number")} />
              ) : null}
            </SimpleShowLayout>
            </Show>

            {record?.representativeName ? (
              <Show actions={false} sx={{ mt: "1em"}}>
              <SimpleShowLayout>
                  <Typography>{translate("contact.representative")}</Typography>
                  <TextField source={"representativeName"} label={translate("contact.name")} />
                {record?.representativeEmailAddress ? (
                  <TextField source={"representativeEmailAddress"} label={translate("contact.email_address")} />
                ) : null}
                {record?.representativePhoneNumber ? (
                  <TextField source={"representativePhoneNumber"} label={translate("contact.phone_number")} />
                ) : null}
              </SimpleShowLayout>
              </Show>
            ) : null}
        </TabPanel>

        <TabPanel value={tabstate} index={1}>
          <Show actions={false}>
            <SimpleShowLayout>
              <TextField label="Street Name" source={"address.streetName"} />
              <TextField label="House Number" source={"address.streetNumber"} />
              <TextField label="Postal Code" source={"address.postalCode"} />
              <TextField label="City" source={"address.city"} />
            </SimpleShowLayout>
          </Show>
        </TabPanel>
      </CardContent>
    </Show>
  );
};

const ShowPlannedEvents = () => {
  const record = useRecordContext();

  if (!record) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  const eid = record.id.split("/")[3];

  // Find viewport height
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );

  return (
    <ResourceContextProvider value="planned_events">
      <PlannedEventList
        filter={{ employees: eid }}
        filters={false}
        actions={false}
        disableSyncWithLocation
        height={vh * 0.76}
        defaultView={EventScheduler.WEEK_VIEW}
        allowEdits={false}
        mainResourceName="customer"
      />
    </ResourceContextProvider>
  );
};

const ShowEventReports = () => {
  const record = useRecordContext();

  if (!record) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  const eid = record.id.split("/")[3];

  return (
    <ResourceContextProvider value={"event_reports"}>
      <EventReportList
        sx={{
          '& div.RaList-actions': {
            '&:not(:has(div[class*="filter-field"]))': {
              marginTop: '-64px',
              width: '25%',
              alignSelf: 'flex-end',
            },
          }
        }}
        filter={{ owner: eid }}
        showOwner={false}
        showInvoices={false}
        filters={[
          <ReferenceInput
            label="clients.show.client"
            source="customer"
            reference="customers"
            filter={{ employees: eid }}
          >
            <SelectInput label="clients.show.client" optionText="fullName" />
          </ReferenceInput>,
        ]}
        actions={
          <EventReportListActions showExport={false} showCreate={false} />
        }
        disableSyncWithLocation
        allowSelect={false}
      />
    </ResourceContextProvider>
  );
};

const ShowCustomers = () => {
  const record = useRecordContext();

  if (!record) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  const eid = record.id.split("/")[3];

  return (
    <ResourceContextProvider value={"customers"}>
      <CustomerList
        filter={{ employees: eid, isActive: true }}
        filters={null}
        actions={null}
        aside={null}
        tabbed={false}
      />
    </ResourceContextProvider>
  );
};

const ShowPayrollItems = () => {
  const record = useRecordContext();

  if (!record) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  const eid = record.id.split("/")[3];

  return (
    <ResourceContextProvider value={"payroll_items"}>
      <PayrollItemList
        filter={{ employee: eid }}
        showEmployee={false}
        filters={null}
        actions={null}
        allowSelect={false}
      />
    </ResourceContextProvider>
  );
};

/**
 * This exists because we need the record, which is only available inside the <Show>
 */
const EmployeeShowContent = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { isLoading, record } = useShowContext();
  const [tabstate, setValue] = React.useState(0);
  const changeTab = (event, newValue) => {
    setValue(newValue);
  };
  const translate = useTranslate();

  if (isLoading || identityLoading) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Tabs
        value={tabstate}
        textColor="primary"
        indicatorColor="primary"
        onChange={changeTab}
      >
        <Tab label={translate("employees.show.planning")} />
        <Tab label={translate("employees.show.reports")} />
        <Tab label={translate("employees.show.clients")} />

        {(identity.roles.some((e) =>
          ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
        ) ||
          identity.id === record?.id.split("/")[3]) && <Tab label={translate("employees.show.payroll")} />}

        {identity.roles.some((e) =>
          ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
        ) && <Tab label={translate("employees.show.contract")} />}

        {identity.roles.some((e) => ["ROLE_ADMIN"].includes(e)) && (
          <Tab label="Raw" />
        )}
      </Tabs>
      <Divider />

      <TabPanel value={tabstate} index={0}>
        <ShowPlannedEvents />
      </TabPanel>

      <TabPanel value={tabstate} index={1}>
        <ShowEventReports />
      </TabPanel>

      <TabPanel value={tabstate} index={2}>
        <ShowCustomers />
      </TabPanel>

      {(identity.roles.some((e) =>
        ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
      ) ||
        identity.id === record?.id.split("/")[3]) && (
        <TabPanel value={tabstate} index={3}>
          <ShowPayrollItems />
        </TabPanel>
      )}

      {identity.roles.some((e) =>
        ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
      ) && (
        <TabPanel value={tabstate} index={4}>
          <ShowEmployeeContract />
        </TabPanel>
      )}

      {identity.roles.some((e) => ["ROLE_ADMIN"].includes(e)) && (
        <TabPanel value={tabstate} index={5}>
          <ShowGuesser actions={false} />
        </TabPanel>
      )}
    </React.Fragment>
  );
};

const ShowEmployeeContract = () => {
  const record = useRecordContext();
  const classes = showEmployeeContractStyles();

  return (
    <Container className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.cardContentTitle} align="center">
            Hours per Week:
          </Typography>
          <Box className={classes.cardContentBox}>
            <Typography
              className={classes.cardContentValue}
              align="center"
              variant="h2"
            >
              {record?.weeklyHours}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.cardContentTitle} align="center">
            Salary Scale:
          </Typography>
          <Box className={classes.cardContentBox}>
            <Typography
              className={classes.cardContentValue}
              align="center"
              variant="h4"
            >
              {record?.salaryScale}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.cardContentTitle} align="center">
            Start Date:
          </Typography>
          <Box className={classes.cardContentBox}>
            <Typography
              className={classes.cardContentValue}
              align="center"
              variant="h4"
            >
              {record?.startDate}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.cardContentTitle} align="center">
            End Date:
          </Typography>
          <Box className={classes.cardContentBox}>
            <Typography
              className={classes.cardContentValue}
              align="center"
              variant="h4"
            >
              {record?.endDate}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

/**
 * Main (tabbed) content area
 */
const EmployeeShow = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();

  if (identityLoading) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  let actions = false;
  identity.roles.some((e) => ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)) &&
    (actions = undefined);

  return identity ? (
    <Show aside={<Aside />} actions={actions}>
      <EmployeeShowContent />
    </Show>
  ) : null;
};

export { EmployeeShow };
