import * as React from "react";
import {
  EditBase,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  required,
  useEditContext,
  useNotify,
  useRedirect,
  useGetList,
  BooleanInput,
  FormDataConsumer,
  useUpdate,
  Form,
  TimeInput,
  LinearProgress,
} from "react-admin";
import {
  Card,
  CardContent,
  Box,
  Avatar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Receipt } from "@mui/icons-material";
import { format } from "date-fns";
import { parse } from "date-fns";

const Spacer = () => <Box width={20} component="span" />;

function isNumeric(str) {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    width: "60px",
    height: "60px",
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
  formCard: {
    width: "100%",
  },
  formIteratorXSmall: {
    "& li": {
      "& section": {
        flexDirection: "row !important",
        flexWrap: "wrap",
        "& div.ra-input": {
          width: "45%",
          marginRight: "auto",
          "& div": {
            minWidth: 0,
            "& p": {
              display: "none",
            },
          },
        },
      },
    },
  },
  formIterator: {
    "& li": {
      "& section": {
        flexDirection: "row !important",
        "& div.ra-input": {
          marginRight: "10px",
          "& div": {
            minWidth: 0,
          },
        },
      },
    },
  },
}));

const EventTypeSelectInput = ({ formData }) => {
  const cid = formData?.customer?.split("/")[3];
  const { data, isLoading } = useGetList(
    "event_types",
    {
      pagination: { pagination: false },
      filter: { pagination: false, customers: cid, billable: true },
    },
    {
      enabled: cid !== undefined,
    }
  );

  if (!data) {
    return (
      <SelectInput
        source={"path"}
        label={"Select a Customer first"}
        helperText="Select the EventType"
        choices={[]}
        translateChoice={false}
        validate={[required()]}
        fullWidth
        disabled
      />
    );
  }

  if (isLoading) {
    return (
      <Box mt={2} mb={2} display="flex" justifyContent="center">
        <LinearProgress />
      </Box>
    );
  }

  const singleChoices = [];
  const rangeChoices = [];
  data.map((eventType) => {
    singleChoices.push({
      id: `/custom_price/${eventType["tag"]}/rate`,
      name: `${eventType["name"]} [${eventType["tag"]}]`,
    });
    rangeChoices.push({
      id: `/custom_price/${eventType["tag"]}/rangeRates`,
      name: `${eventType["name"]} [${eventType["tag"]}]`,
    });
    return "";
  });

  const choices = formData.isRange ? rangeChoices : singleChoices;

  return (
    <SelectInput
      source={"path"}
      choices={choices}
      label={"Event type"}
      helperText="The Event Type"
      validate={[required()]}
      fullWidth
    />
  );
};

const transformValues = async (values) => {
  if (Array.isArray(values.value)) {
    values.value = values.value
      ? values.value.map((item) => {
          let day = item.range.day;
          let begin = format(new Date(item.range.begin), "HH:mm");
          let end = format(new Date(item.range.end), "HH:mm");
          item.range = `${day},${begin},${end}`;
          item.rate = item.rate.toString();
          return item;
        })
      : undefined;
    values.value = JSON.stringify(values.value);
  } else {
    values.value = values.value.toString();
  }

  return values;
};

const transformRangeValue = (record) => {
  if (record) {
    if (!isNumeric(record.value) && !Array.isArray(record.value)) {
      record.isRange = true;
      record.value = JSON.parse(record.value);
      record.value.forEach((rangeRate, index) => {
        if (typeof rangeRate.range === "string") {
          const arr = rangeRate.range.split(",");
          record.value[index].range = {
            day: arr[0],
            begin: parse(arr[1], "HH:mm", new Date()),
            end: parse(arr[2], "HH:mm", new Date()),
          };
          return record;
        }
      });
    }
  }
};

const CustomerParameterEditContent = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const { record } = useEditContext();
  const transformedRecord = transformRangeValue(record);
  const [update] = useUpdate();
  const save = React.useCallback(
    async (values) => {
      await transformValues(values);
      try {
        await update(
          "customer_parameters",
          { id: record ? record.id : null, data: values },
          { returnPromise: true }
        ).then((response) => {
          const custid = encodeURIComponent(values.customer);
          redirect(`/customers/${custid}/show`);
        });
      } catch (error) {
        let errors = {};
        error.body[0]["http://www.w3.org/ns/hydra/core#description"][0][
          "@value"
        ]
          .split(/\n/)
          .forEach((e) => {
            notify(e, { type: "warning" });
            const err = e.split(": ");
            errors[err[0]] = err[1];
          });
        notify("ra.notification.edit.failure", {
          type: "warning",
        });
        return errors;
      }
    },
    [update, notify, redirect, record]
  );
  const classes = useStyles();
  const isBig = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        display: "flex",
        marginTop: 2,
        width: "100%",
        maxWidth: 800,
        alignSelf: "center",
        "& form": {
          width: "100%",
        },
      }}
    >
      <Form record={record} onSubmit={save}>
        <Card className={classes.formCard}>
          <CardContent>
            <Box>
              <Box display="flex">
                <Box flex={1}></Box>
                <Box flex={1} display="flex" justifyContent="center">
                  <Avatar className={classes.avatarContainer}>
                    <Receipt className={classes.avatar} />
                  </Avatar>
                </Box>
                <Box flex={1} display="flex" justifyContent="flex-end">
                  <BooleanInput
                    label="Set range rates"
                    source="isRange"
                    disabled
                  />
                </Box>
              </Box>
              <Box mt={2} display="flex" justifyContent="center">
                <Typography>Custom Pricing</Typography>
              </Box>
              <Box display="flex">
                <ReferenceInput source="customer" reference="customers">
                  <SelectInput
                    optionText="fullName"
                    helperText="The related Customer entity."
                    translateChoice={false}
                    validate={[required()]}
                    fullWidth
                    disabled
                  />
                </ReferenceInput>
              </Box>
              <FormDataConsumer>
                {({ formData }) =>
                  formData.isRange ? (
                    <Box>
                      <EventTypeSelectInput formData={formData} />
                      <Box mt={2} display="flex" justifyContent="center">
                        <Typography>Time-range based rates</Typography>
                      </Box>
                      <ArrayInput
                        record={transformedRecord}
                        source="value"
                        label={false}
                        validate={[required()]}
                      >
                        <SimpleFormIterator
                          className={
                            isBig
                              ? classes.formIterator
                              : classes.formIteratorXSmall
                          }
                        >
                          <SelectInput
                            label="Weekday"
                            source="range.day"
                            helperText="The weekday the rate will be effective"
                            allowEmpty={false}
                            choices={[
                              { id: "Mon", name: "Monday" },
                              { id: "Tue", name: "Tuesday" },
                              { id: "Wed", name: "Wednesday" },
                              { id: "Thu", name: "Thursday" },
                              { id: "Fri", name: "Friday" },
                              { id: "Sat", name: "Saturday" },
                              { id: "Sun", name: "Sunday" },
                            ]}
                            fullWidth
                            validate={[required()]}
                          />
                          <TimeInput
                            label="Begin time"
                            source="range.begin"
                            helperText="Start of the time range"
                            options={{ format: "HH:mm" }}
                            fullWidth
                            validate={[required()]}
                          />
                          <TimeInput
                            label="End time"
                            source="range.end"
                            helperText="End of the time range"
                            options={{ format: "HH:mm" }}
                            fullWidth
                            validate={[required()]}
                          />
                          <NumberInput
                            min={"0.01"}
                            step={"0.01"}
                            label="Effective rate"
                            source="rate"
                            helperText="The effective rate for the given range"
                            validate={[required()]}
                            fullWidth
                          />
                        </SimpleFormIterator>
                      </ArrayInput>
                    </Box>
                  ) : (
                    <Box display="flex">
                      <EventTypeSelectInput formData={formData} />
                      <Spacer />
                      <NumberInput
                        min={"0.01"}
                        step={"0.01"}
                        label="Custom rate/hr."
                        source="value"
                        helperText="The custom hourly rate."
                        validate={[required()]}
                        fullWidth
                      />
                    </Box>
                  )
                }
              </FormDataConsumer>
            </Box>
          </CardContent>
          <Toolbar />
        </Card>
      </Form>
    </Box>
  );
};

const CustomerParameterEdit = () => {
  return (
    <EditBase
      mutationMode="pessimistic"
      transform={(data) => ({
        ...data,
      })}
    >
      <CustomerParameterEditContent />
    </EditBase>
  );
};

export { CustomerParameterEdit };
