import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { WeekView as DxWeekView } from "@devexpress/dx-react-scheduler-material-ui";

const useStyles = makeStyles((theme) => ({
  defaultCell: {
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.14),
    },
    "&:focus": {
      backgroundColor: alpha(theme.palette.primary.main, 0.16),
    },
  },
  todayCell: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.14),
    },
    "&:focus": {
      backgroundColor: alpha(theme.palette.primary.main, 0.16),
    },
  },
  weekendCell: {
    backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.14),
    },
    "&:focus": {
      backgroundColor: alpha(theme.palette.primary.main, 0.16),
    },
  },
  today: {
    backgroundColor: alpha(theme.palette.primary.main, 0.16),
  },
  weekend: {
    backgroundColor: alpha(theme.palette.action.disabledBackground, 0.06),
  },
}));

const TimeTableCell = (props) => {
  const classes = useStyles();
  const { startDate } = props;
  const date = new Date(startDate);

  if (date.getDate() === new Date().getDate()) {
    return (
      <DxWeekView.TimeTableCell {...props} className={classes.todayCell} />
    );
  }
  if (date.getDay() === 0 || date.getDay() === 6) {
    return (
      <DxWeekView.TimeTableCell {...props} className={classes.weekendCell} />
    );
  }
  return (
    <DxWeekView.TimeTableCell {...props} className={classes.defaultCell} />
  );
};

const DayScaleCell = (props) => {
  const classes = useStyles();
  const { startDate, today } = props;

  if (today) {
    return <DxWeekView.DayScaleCell {...props} className={classes.today} />;
  }
  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
    return <DxWeekView.DayScaleCell {...props} className={classes.weekend} />;
  }
  return <DxWeekView.DayScaleCell {...props} />;
};

const WeekView = (props) => {
  return (
    <DxWeekView
      {...props}
      timeTableCellComponent={TimeTableCell}
      dayScaleCellComponent={DayScaleCell}
    />
  );
};

export { WeekView };
