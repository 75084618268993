import * as React from "react";
// import { makeStyles } from "@mui/styles";
import {
  TopToolbar,
  // SortButton,
  ExportButton,
  // CreateButton,
  useGetIdentity,
  LinearProgress,
} from "react-admin";

// const useEmployeeListActionsStyles = makeStyles((theme) => ({
//   createButton: {
//     marginLeft: theme.spacing(2),
//   },
// }));

const EmployeeListActions = () => {
  const { data: identity, isLoading } = useGetIdentity();
  // const classes = useEmployeeListActionsStyles();

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <TopToolbar>
      {/*<SortButton fields={["fullName", "createdAt"]} />*/}
      {identity.roles.some((e) =>
        ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
      ) && (
        <React.Fragment>
          <ExportButton />
          {/*<CreateButton
            className={classes.createButton}
            basePath="/employees"
            variant="contained"
            label="Add Employee"
          />*/}
        </React.Fragment>
      )}
    </TopToolbar>
  );
};

export { EmployeeListActions };
