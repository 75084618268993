import * as React from "react";
import { useEffect } from "react";
import {
  Avatar,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  CardContent,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import { useCheckAuth } from "ra-core";
import {
  Form,
  useTranslate,
  required,
  useLogin,
  useNotify,
  useSafeSetState,
} from "ra-core";
import { TextInput } from "react-admin";

const LoginPage = (props) => {
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        navigate("/");
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, navigate]);

  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();

  const handleSubmit = (values) => {
    setLoading(true);
    login(values, "/")
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          {
            type: "error",
            messageArgs: {
              _:
                typeof error === "string"
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          }
        );
      });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://picsum.photos/1920/1080)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: "50%",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Form
            onSubmit={handleSubmit}
            mode="onChange"
            noValidate
          >
            <CardContent>
              <TextInput
                autoFocus
                source="username"
                label={translate("ra.auth.username")}
                autoComplete="username"
                validate={required()}
                fullWidth
              />
              <TextInput
                source="password"
                label={translate("ra.auth.password")}
                type="password"
                autoComplete="current-password"
                validate={required()}
                fullWidth
              />
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
              >
                {loading ? (
                  <CircularProgress size={19} thickness={3} />
                ) : (
                  translate("ra.auth.sign_in")
                )}
              </Button>
              <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{ mt: 5 }}
            >
              {"Copyright © "}
              <Link color="inherit" href="https://www.zorgsteen.nl/">
                Zorgsteen B.V.
              </Link>
            </Typography>
            </CardContent>
          </Form>
        </Box>
      </Grid>
    </Grid>
  );
};

export { LoginPage };
