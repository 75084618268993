import { EmployeeList } from "./EmployeeList";
import { EmployeeShow } from "./EmployeeShow";
// import { EmployeeCreate } from './EmployeeCreate';
import { EmployeeEdit } from "./EmployeeEdit";

const Employees = {
  list: EmployeeList,
  show: EmployeeShow,
  create: null,
  edit: EmployeeEdit,
};

export { Employees };
