import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";

const Appointment = ({ children, style, ...restProps }) => {
  return (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        borderRadius: "12px",
      }}
    >
      {children}
    </Appointments.Appointment>
  );
};

export { Appointment };
