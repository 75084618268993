import * as React from "react";
import {
  CreateBase,
  TextInput,
  NumberInput,
  BooleanInput,
  ArrayInput,
  SelectInput,
  Form,
  FormDataConsumer,
  SimpleFormIterator,
  Toolbar,
  required,
  useCreateContext,
  useCreate,
  useNotify,
  useRedirect,
  ReferenceArrayInput,
  SelectArrayInput,
  TimeInput,
  useTranslate,
} from "react-admin";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Avatar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Ballot } from "@mui/icons-material";
import { format } from "date-fns";

const Spacer = () => <Box width={20} component="span" />;

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    width: "60px",
    height: "60px",
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
  formCard: {
    width: "100%",
  },
  formIteratorXSmall: {
    "& li": {
      "& section": {
        flexDirection: "row !important",
        flexWrap: "wrap",
        "& div.ra-input": {
          width: "45%",
          marginRight: "auto",
          "& div": {
            minWidth: 0,
            "& p": {
              display: "none",
            },
          },
        },
      },
    },
  },
  formIterator: {
    "& li": {
      "& section": {
        flexDirection: "row !important",
        "& div.ra-input": {
          marginRight: "10px",
          "& div": {
            minWidth: 0,
          },
        },
      },
    },
  },
}));

const transformValues = async (values) => {
  values.hourlyRate =
    values.hourlyRate !== undefined ? values.hourlyRate.toString() : undefined;
  values.VAT = values.VAT !== undefined ? values.VAT.toString() : undefined;
  values.templateInvoiceItems = values.templateInvoiceItems
    ? values.templateInvoiceItems.map((item) => {
        item.percentage = item.percentage.toString();
        return item;
      })
    : undefined;

  values.rangeRates = values.rangeRates
    ? values.rangeRates.map((item) => {
        let day = item.range.day;
        let begin = format(new Date(item.range.begin), "HH:mm");
        let end = format(new Date(item.range.end), "HH:mm");
        item.range = `${day},${begin},${end}`;
        item.rate = item.rate.toString();
        return item;
      })
    : undefined;

  if (values.onCall) {
    values.defaultOnCallRanges = values.defaultOnCallRanges
      ? values.defaultOnCallRanges.map((item) => {
          item.startTime = format(new Date(item.startTime), "HH:mm");
          item.endTime = format(new Date(item.endTime), "HH:mm");
          return item;
        })
      : undefined;
  } else {
    values.defaultOnCallRanges = [];
  }

  return values;
};

const EventTypeCreateContent = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const { record } = useCreateContext();
  const [create] = useCreate();
  const save = React.useCallback(
    async (values) => {
      await transformValues(values);
      try {
        await create(
          "event_types",
          { data: values },
          { returnPromise: true }
        ).then((response) => {
          redirect(`/configuration?tab=services`);
        });
      } catch (error) {
        let errors = {};
        error.body[0]["http://www.w3.org/ns/hydra/core#description"][0][
          "@value"
        ]
          .split(/\n/)
          .forEach((e) => {
            notify(e, { type: "warning" });
            const err = e.split(": ");
            errors[err[0]] = err[1];
          });
        notify("ra.notification.create.failure", {
          type: "warning",
        });
        return errors;
      }
    },
    [create, notify, redirect]
  );
  const classes = useStyles();
  const isBig = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const translate = useTranslate();
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: 2,
        width: "100%",
        maxWidth: 800,
        alignSelf: "center",
        "& form": {
          width: "100%",
        },
      }}
    >
      <Form record={record} onSubmit={save}>
        <Card className={classes.formCard}>
          <CardContent>
            <Box>
              <Box display="flex">
                <Box flex={1} display="flex" justifyContent="flex-start">
                  <React.Fragment>
                    <FormDataConsumer>
                      {({ formData, ...rest }) =>
                        formData.billable ? (
                          <BooleanInput
                            label={translate("configuration.services.on_call")}
                            source="onCall"
                            defaultValue={false}
                          />
                        ) : (
                          <BooleanInput
                            label={translate("configuration.services.on_call")}
                            source="onCall"
                            defaultValue={false}
                            value={false}
                            disabled
                          />
                        )
                      }
                    </FormDataConsumer>
                  </React.Fragment>
                </Box>
                <Box flex={1} display="flex" justifyContent="center">
                  <Avatar className={classes.avatarContainer}>
                    <Ballot className={classes.avatar} />
                  </Avatar>
                </Box>
                <Box flex={1} display="flex" justifyContent="flex-end">
                  <React.Fragment>
                    <FormDataConsumer>
                      {({ formData, ...rest }) =>
                        formData.billable ? (
                          <BooleanInput
                            label={translate("configuration.services.global")}
                            source="global"
                            defaultValue={false}
                          />
                        ) : (
                          <BooleanInput
                            label={translate("configuration.services.global")}
                            source="global"
                            defaultValue={false}
                            value={false}
                            disabled
                          />
                        )
                      }
                    </FormDataConsumer>
                    <BooleanInput
                      label={translate("configuration.services.billable")}
                      source="billable"
                      defaultValue={false}
                    />
                  </React.Fragment>
                </Box>
              </Box>
              <Box mt={2} display="flex" justifyContent="center">
                <Typography>
                  {translate("configuration.services.form.details")}
                </Typography>
              </Box>
              <Box display="flex">
                <TextInput
                  label={translate("configuration.services.form.name")}
                  source="name"
                  helperText={translate(
                    "configuration.services.form.help.name"
                  )}
                  validate={[required()]}
                  fullWidth
                />
                <Spacer />
                <TextInput
                  label={translate("configuration.services.form.tag")}
                  source="tag"
                  helperText={translate("configuration.services.form.help.tag")}
                  validate={[required()]}
                  fullWidth
                />
              </Box>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.billable ? (
                    <Box>
                      <FormDataConsumer>
                        {({ formData, ...rest }) =>
                          formData.onCall ? (
                            <React.Fragment>
                              <Divider />
                              <Box
                                mt={2}
                                display="flex"
                                justifyContent="center"
                              >
                                <Typography>
                                  {translate(
                                    "configuration.services.on_call_active.title"
                                  )}
                                </Typography>
                              </Box>
                              <ArrayInput
                                source="defaultOnCallRanges"
                                label={false}
                                validate={[required()]}
                              >
                                <SimpleFormIterator
                                  className={
                                    isBig
                                      ? classes.formIterator
                                      : classes.formIteratorXSmall
                                  }
                                >
                                  <SelectInput
                                    label={translate(
                                      "configuration.services.on_call_active.start_day"
                                    )}
                                    source="startDay"
                                    helperText={translate(
                                      "configuration.services.form.help.on_call.start_day"
                                    )}
                                    allowEmpty={false}
                                    choices={[
                                      {
                                        id: "1",
                                        name: translate(
                                          "configuration.services.form.on_call.first"
                                        ),
                                      },
                                      {
                                        id: "2",
                                        name: translate(
                                          "configuration.services.form.on_call.last"
                                        ),
                                      },
                                    ]}
                                    defaultValue={"1"}
                                    fullWidth
                                    validate={[required()]}
                                  />
                                  <TimeInput
                                    label={translate(
                                      "configuration.services.on_call_active.start_time"
                                    )}
                                    source="startTime"
                                    helperText={translate(
                                      "configuration.services.form.help.on_call.start_time"
                                    )}
                                    options={{ format: "HH:mm" }}
                                    fullWidth
                                    validate={[required()]}
                                  />
                                  <SelectInput
                                    label={translate(
                                      "configuration.services.on_call_active.end_day"
                                    )}
                                    source="endDay"
                                    helperText={translate(
                                      "configuration.services.form.help.on_call.end_day"
                                    )}
                                    allowEmpty={false}
                                    choices={[
                                      {
                                        id: "1",
                                        name: translate(
                                          "configuration.services.form.on_call.first"
                                        ),
                                      },
                                      {
                                        id: "2",
                                        name: translate(
                                          "configuration.services.form.on_call.last"
                                        ),
                                      },
                                    ]}
                                    defaultValue={"1"}
                                    fullWidth
                                    validate={[required()]}
                                  />
                                  <TimeInput
                                    label={translate(
                                      "configuration.services.on_call_active.end_time"
                                    )}
                                    source="endTime"
                                    helperText={translate(
                                      "configuration.services.form.help.on_call.end_time"
                                    )}
                                    options={{ format: "HH:mm" }}
                                    fullWidth
                                    validate={[required()]}
                                  />
                                </SimpleFormIterator>
                              </ArrayInput>
                            </React.Fragment>
                          ) : null
                        }
                      </FormDataConsumer>
                      <Divider />
                      <Box mt={2} display="flex" justifyContent="center">
                        <Typography>
                          {translate(
                            "configuration.services.form.billing_details"
                          )}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <NumberInput
                          min={"0.01"}
                          step={"0.01"}
                          label={translate(
                            "configuration.services.hourly_rate"
                          )}
                          source="hourlyRate"
                          helperText={translate(
                            "configuration.services.form.help.hourly_rate"
                          )}
                          validate={[required()]}
                          fullWidth
                        />
                        <Spacer />
                        <NumberInput
                          min={"0"}
                          step={"0.01"}
                          label={translate("configuration.services.VAT")}
                          source="VAT"
                          helperText={translate(
                            "configuration.services.form.help.VAT"
                          )}
                          validate={[required()]}
                          fullWidth
                        />
                      </Box>
                      <Divider />
                      <Box mt={2} display="flex" justifyContent="center">
                        <Typography>
                          {translate(
                            "configuration.services.range_rates.title"
                          )}
                        </Typography>
                      </Box>
                      <ArrayInput source="rangeRates" label={false}>
                        <SimpleFormIterator
                          className={
                            isBig
                              ? classes.formIterator
                              : classes.formIteratorXSmall
                          }
                        >
                          <SelectInput
                            label={translate(
                              "configuration.services.form.range_rates.week_day"
                            )}
                            source="range.day"
                            helperText={translate(
                              "configuration.services.form.help.range_rates.week_day"
                            )}
                            allowEmpty={false}
                            choices={[
                              {
                                id: "Mon",
                                name: translate(
                                  "configuration.services.form.range_rates.days.mon"
                                ),
                              },
                              {
                                id: "Tue",
                                name: translate(
                                  "configuration.services.form.range_rates.days.tue"
                                ),
                              },
                              {
                                id: "Wed",
                                name: translate(
                                  "configuration.services.form.range_rates.days.wed"
                                ),
                              },
                              {
                                id: "Thu",
                                name: translate(
                                  "configuration.services.form.range_rates.days.thu"
                                ),
                              },
                              {
                                id: "Fri",
                                name: translate(
                                  "configuration.services.form.range_rates.days.fri"
                                ),
                              },
                              {
                                id: "Sat",
                                name: translate(
                                  "configuration.services.form.range_rates.days.sat"
                                ),
                              },
                              {
                                id: "Sun",
                                name: translate(
                                  "configuration.services.form.range_rates.days.sun"
                                ),
                              },
                            ]}
                            fullWidth
                            validate={[required()]}
                          />
                          <TimeInput
                            label={translate(
                              "configuration.services.form.range_rates.begin_time"
                            )}
                            source="range.begin"
                            helperText={translate(
                              "configuration.services.form.help.range_rates.begin_time"
                            )}
                            options={{ format: "HH:mm" }}
                            fullWidth
                            validate={[required()]}
                          />
                          <TimeInput
                            label={translate(
                              "configuration.services.form.range_rates.end_time"
                            )}
                            source="range.end"
                            helperText={translate(
                              "configuration.services.form.help.range_rates.end_time"
                            )}
                            options={{ format: "HH:mm" }}
                            fullWidth
                            validate={[required()]}
                          />
                          <NumberInput
                            min={"0.01"}
                            step={"0.01"}
                            label={translate(
                              "configuration.services.form.range_rates.rate"
                            )}
                            source="rate"
                            helperText={translate(
                              "configuration.services.form.help.range_rates.rate"
                            )}
                            validate={[required()]}
                            fullWidth
                          />
                        </SimpleFormIterator>
                      </ArrayInput>
                      <Divider />
                      <Box mt={2} display="flex" justifyContent="center">
                        <Typography>
                          {translate(
                            "configuration.services.template_invoice_lines.title"
                          )}
                        </Typography>
                      </Box>
                      <ArrayInput
                        source="templateInvoiceItems"
                        label={false}
                        validate={[required()]}
                      >
                        <SimpleFormIterator
                          className={
                            isBig
                              ? classes.formIterator
                              : classes.formIteratorXSmall
                          }
                        >
                          <NumberInput
                            min={"0.01"}
                            step={"0.01"}
                            label={translate(
                              "configuration.services.template_invoice_lines.share"
                            )}
                            source="percentage"
                            helperText={translate(
                              "configuration.services.form.help.template_invoice_lines.share"
                            )}
                            validate={[required()]}
                            fullWidth
                          />
                          <TextInput
                            label={translate(
                              "configuration.services.template_invoice_lines.template"
                            )}
                            source="description"
                            helperText={translate(
                              "configuration.services.form.help.template_invoice_lines.template"
                            )}
                            validate={[required()]}
                            fullWidth
                          />
                        </SimpleFormIterator>
                      </ArrayInput>
                      <FormDataConsumer>
                        {({ formData, ...rest }) =>
                          formData.global ? null : (
                            <React.Fragment>
                              <Divider />
                              <Box
                                mt={2}
                                display="flex"
                                justifyContent="center"
                              >
                                <Typography>
                                  {translate(
                                    "configuration.services.form.relationships.title"
                                  )}
                                </Typography>
                              </Box>
                              <ReferenceArrayInput
                                source="customers"
                                reference="customers"
                                validate={[required()]}
                              >
                                <SelectArrayInput
                                  optionText="fullName"
                                  label={translate(
                                    "configuration.services.form.relationships.customers"
                                  )}
                                  helperText={translate(
                                    "configuration.services.form.help.relationships.customers"
                                  )}
                                  translateChoice={false}
                                  fullWidth
                                />
                              </ReferenceArrayInput>
                            </React.Fragment>
                          )
                        }
                      </FormDataConsumer>
                    </Box>
                  ) : null
                }
              </FormDataConsumer>
            </Box>
          </CardContent>
          <Toolbar />
        </Card>
      </Form>
    </Box>
  );
};

const EventTypeCreate = () => {
  return (
    <CreateBase
      transform={(data) => ({
        ...data,
      })}
    >
      <EventTypeCreateContent />
    </CreateBase>
  );
};

export { EventTypeCreate };
