import { CustomerParameterList } from "./CustomerParameterList";
import { CustomerParameterCreate } from "./CustomerParameterCreate";
import { CustomerParameterEdit } from "./CustomerParameterEdit";

const CustomerParameters = {
  list: CustomerParameterList,
  show: null,
  create: CustomerParameterCreate,
  edit: CustomerParameterEdit,
};

export { CustomerParameters };
