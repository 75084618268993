import merge from "lodash/merge";
import englishMessages from "ra-language-english";

const English = merge(englishMessages, {
  ra: {
    notification: {
      http_error: "An error has occurred. Please try again later.",
      create: {
        failure: "The object could not be created due to an error.",
      },
      edit: {
        failure: "The object could not be edited due to an error.",
      },
      planning: {
        create: {
          success: "Appointment created successfully.",
          failure: "Failed to create appointment due to an error: %{err}",
        },
        update: {
          success: "Appointment updated successfully.",
          failure: "Failed to update appointment due to an error: %{err}",
        },
      },
    },
    action: {
      // list: 'List',
      // show: 'Show',
      // edit: 'Edit',
      // create: 'Create',
      // delete: 'Delete',
      // save: 'Save',
      // cancel: 'Cancel',
    },
    auth: {
      username: "Username",
      password: "Password",
    },
  },
  messages: {
    none: "None",
    today: "Today",
    day: "Day",
    week: "Week",
    month: "Month",
  },
  impersonation: {
    start: "Impersonate",
    stop: "Stop Impersonation",
  },
  contact: {
    details: "Contact Details",
    address: "Address",
    representative: "Representative",
    name: "Name",
    lastName: "Last name",
    legalName: "Legal name",
    email_address: "Email address",
    phone_number: "Phone number",
    social_registration_number: "Social registration number",
  },
  main_menu: {
    dashboard: "Dashboard",
    clients: "Clients",
    colleagues: "Colleagues",
    planning: "Planning",
    reporting: "Reports",
    invoices: "Invoices",
    payroll_items: "Payroll",
    configuration: "Configuration",
  },
  dashboard: {
    planning: {
      title: "My Planning",
    },
    missing_reports: {
      title: "Missing Reports",
      in_development: "IN DEVELOPMENT",
    },
    reports: {
      title: "Latest Reports",
      not_found: "NO REPORTS FOUND",
      show_all: "Show all",
      dialog_title: "All Reports",
    },
    clients: {
      title: "My Clients",
    },
  },
  clients: {
    list: {
      add: "Add Client",
      current: "Active",
      archived: "Archived",
    },
    show: {
      client: "Client",
      planning: "Planning",
      reports: "Reports",
      team: "Team",
      invoices: "Invoices",
    },
    custom_pricing: {
      title: "Custom Pricing",
      add: "Add Custom Price",
      custom_price: "Custom Price",
    },
    edit: {
      personal_information: "Personal Information Client",
      company_information: "Company Information",
    },
  },
  employees: {
    list: {
      add: "Add Employee",
      current: "Active",
      archived: "Archived",
    },
    show: {
      employee: "Employee",
      planning: "Planning",
      reports: "Reports",
      clients: "Clients",
      payroll: "Payroll",
      contract: "Contract",
    },
    edit: {
      personal_information: "Personal Information Employee",
      contract_details: "Contract Details",
    },
  },
  planning: {
    add: {
      client: "Client",
      employees: "Employee(s)",
      service: "Service",
    },
    representation: {
      for: "for",
      at: "at",
      from: "from",
      to: "to",
    },
  },
  reporting: {
    add: "Add Report",
    start: "Start",
    end: "End",
    service: "Service",
    invoices: "Invoices",
    representation: {
      for: "for",
      reported_by: "reported by",
    },
    form: {
      title: "Service Report",
      relationships: "Relationships",
      hours: "hours",
      minutes: "minutes",
      help: {
        start: "Time and date your shift started",
        end: "Time and date your shift ended",
        comments: "Describe what happened and what you observed during your shift",
        enter_dates: "Please enter the start/end date first",
        customer: "The client you're reporting about",
        event_type: "The service you're reporting about",
        owner: "The employee submitting the report",
      },
    },
  },
  invoices: {
    invoiceNo: 'Invoice',
    period: 'Invoice period',
    totalMinutes: 'Minutes',
    totalHours: 'Hours',
    totalVAT: 'Total VAT',
    totalDue: 'Total due',
    totalDays: 'Days',
    list: {
      hours: 'Hours',
      minutes: 'Minutes',
      description: 'Description',
      hourlyRate: 'Hourly rate',
      VAT: 'VAT%',
      total: 'Subtotal',
    },
  },
  payroll: {
    period: 'Payroll period',
    grossTotal: 'Gross total',
    list: {
      date: "Date",
      flatFee: "Flat fee/hr.",
      ORT: "ORT%",
    },
  },
  configuration: {
    menu: {
      services: "Services & Billing",
    },
    services: {
      add: "Add Services",
      title: "Service",
      hourly_rate: "Hourly rate",
      VAT: "VAT%",
      billable: "Billable",
      global: "Global",
      on_call: "On-Call",
      on_call_active: {
        title: "On-Call & Active",
        start_day: "Start day",
        start_time: "Start time",
        end_day: "End day",
        end_time: "End time",
      },
      range_rates: {
        title: "Rates per period",
        range: "Period",
        rate: "Effective rate",
      },
      template_invoice_lines: {
        title: "Invoice Lines",
        share: "Share",
        template: "Template",
      },
      form: {
        details: "Details",
        billing_details: "Billing details",
        name: "Name",
        tag: "Tag",
        on_call: {
          first: "First",
          last: "Last",
        },
        range_rates: {
          week_day: "Weekday",
          begin_time: "Begin time",
          end_time: "End time",
          rate: "Effective rate",
          days: {
            mon: "Monday",
            tue: "Tuesday",
            wed: "Wednesday",
            thu: "Thursday",
            fri: "Friday",
            sat: "Saturday",
            sun: "Sunday",
          },
        },
        relationships: {
          title: "Relationships",
          customers: "Clients",
        },
        help: {
          name: "The name of the service",
          tag: "The tag is used for internal references",
          hourly_rate: "The billed compensation per hour",
          VAT: "The VAT percentage",
          on_call: {
            start_day: "Which day the period starts",
            start_time: "What time the period starts",
            end_day: "Which day the period ends",
            end_time: "What time the period ends",
          },
          range_rates: {
            week_day: "The day on which the rate applies",
            begin_time: "From which time the rate applies",
            end_time: "Until which time the rate applies",
            rate: "The effective rate for the given period",
          },
          template_invoice_lines: {
            share:
              "Percentage of reported time that appears on the invoice line",
            template: "Pattern followed by the invoice line",
          },
          relationships: {
            customers: "The clients for whom this service applies",
          },
        },
      },
    },
  },
});

export { English };
