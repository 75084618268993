import * as React from "react";
import {
  EditBase,
  NumberInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  BooleanInput,
  Form,
  FormDataConsumer,
  SimpleFormIterator,
  Toolbar,
  required,
  useEditContext,
  useUpdate,
  useNotify,
  useRedirect,
} from "react-admin";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Avatar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Receipt } from "@mui/icons-material";

const Spacer = () => <Box width={20} component="span" />;

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    width: "60px",
    height: "60px",
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
  formCard: {
    width: "100%",
  },
  eventReports: {
    "&.RaChipField-chip": {
      height: "50px",
    },
  },
  formIteratorXSmall: {
    "& li": {
      "& section": {
        flexDirection: "row !important",
        flexWrap: "wrap",
        "& div.ra-input": {
          width: "45%",
          marginRight: "auto",
          "& div": {
            minWidth: 0,
            "& p": {
              display: "none",
            },
          },
        },
      },
    },
  },
  formIterator: {
    "& li": {
      "& section": {
        flexDirection: "row !important",
        "& div.ra-input": {
          width: "20% !important",
          marginRight: "10px",
          "& div": {
            minWidth: 0,
          },
        },
      },
    },
  },
}));

const transformValues = async (values) => {
  values.invoiceNo = values.invoiceNo.toString();
  values.invoiceItems = values.invoiceItems
    ? values.invoiceItems.map((item) => {
        item.minutes = item.minutes.toString();
        item.hourlyRate = item.hourlyRate.toString();
        item.VAT = item.VAT.toString();
        return item;
      })
    : undefined;
  return values;
};

const InvoiceEditContent = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const { record } = useEditContext();
  const [update] = useUpdate();
  const save = React.useCallback(
    async (values) => {
      await transformValues(values);
      try {
        await update(
          "invoices",
          { id: record ? record.id : null, data: values },
          { returnPromise: true }
        ).then((response) => {
          redirect(`/invoices`);
        });
      } catch (error) {
        let errors = {};
        error.body[0]["http://www.w3.org/ns/hydra/core#description"][0][
          "@value"
        ]
          .split(/\n/)
          .forEach((e) => {
            notify(e, { type: "warning" });
            const err = e.split(": ");
            errors[err[0]] = err[1];
          });
        notify("ra.notification.edit.failure", {
          type: "warning",
        });
        return errors;
      }
    },
    [update, notify, redirect, record]
  );
  const classes = useStyles();
  const isBig = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return record ? (
    <Box
      sx={{
        display: "flex",
        marginTop: 2,
        width: "100%",
        maxWidth: 800,
        alignSelf: "center",
        "& form": {
          width: "100%",
        },
      }}
    >
      <Form record={record} onSubmit={save}>
        <Card className={classes.formCard}>
          <CardContent>
            <Box>
              <Box display="flex">
                <Box flex={1}></Box>
                <Box flex={1} display="flex" justifyContent="center">
                  <Avatar className={classes.avatarContainer}>
                    <Receipt className={classes.avatar} />
                  </Avatar>
                </Box>
                <Box flex={1} display="flex" justifyContent="flex-end">
                  <FormDataConsumer>
                    {({ formData, ...rest }) => (
                      <React.Fragment>
                        <BooleanInput
                          source={"isPaid"}
                          defaultValue={false}
                          disabled={formData.isSent ? false : true}
                          {...rest}
                        />
                        <BooleanInput
                          source={"isSent"}
                          defaultValue={false}
                          disabled={formData.isPaid}
                          {...rest}
                        />
                      </React.Fragment>
                    )}
                  </FormDataConsumer>
                </Box>
              </Box>
              <Box mt={2} display="flex" justifyContent="center">
                <Typography>Invoice Details</Typography>
              </Box>
              <Box display="flex">
                <NumberInput
                  source={"invoiceNo"}
                  helperText="The invoice number."
                  validate={[required()]}
                  fullWidth
                />
                <Spacer />
                <TextInput
                  source={"invoicePeriod"}
                  helperText="The invoice period."
                  validate={[required()]}
                  fullWidth
                />
              </Box>
              <Divider />
              <Box mt={2} display="flex" justifyContent="center">
                <Typography>Customer Details</Typography>
              </Box>
              <Box display="flex">
                <TextInput
                  source={"invoiceTo"}
                  helperText="The name on the invoice."
                  validate={[required()]}
                  fullWidth
                />
                <Spacer />
                <ReferenceInput source="customer" reference="customers">
                  <SelectInput
                    optionText="fullName"
                    helperText="The related Customer entity."
                    translateChoice={false}
                    fullWidth
                  />
                </ReferenceInput>
              </Box>
              <Divider />
              <Box mt={2} display="flex" justifyContent="center">
                <Typography>Invoice Address</Typography>
              </Box>
              <Box display="flex">
                <TextInput
                  label="Street name"
                  source="invoiceAddress.streetName"
                  helperText="The street name."
                  validate={[required()]}
                  fullWidth
                />
                <Spacer />
                <TextInput
                  label="Nr."
                  source="invoiceAddress.streetNumber"
                  helperText="The building number."
                  validate={[required()]}
                />
              </Box>
              <Box display="flex">
                <TextInput
                  label="ZIP code"
                  source="invoiceAddress.postalCode"
                  helperText="The ZIP code."
                  validate={[required()]}
                />
                <Spacer />
                <TextInput
                  label="City"
                  source="invoiceAddress.city"
                  helperText="The city."
                  validate={[required()]}
                  fullWidth
                />
              </Box>
              <Divider />
              <Box mt={2} display="flex" justifyContent="center">
                <Typography>Invoice Lines</Typography>
              </Box>
              <Box display="flex">
                <ArrayInput
                  source="invoiceItems"
                  label={false}
                  validate={[required()]}
                >
                  <SimpleFormIterator
                    className={
                      isBig ? classes.formIterator : classes.formIteratorXSmall
                    }
                  >
                    <NumberInput
                      min={"1"}
                      step={"1"}
                      source={"minutes"}
                      label={"Minutes"}
                      helperText="Minutes billed"
                      validate={[required()]}
                      fullWidth
                    />
                    <TextInput
                      source={"description"}
                      label={"Description"}
                      helperText="Invoice line text"
                      validate={[required()]}
                      fullWidth
                    />
                    <TextInput
                      source={"date"}
                      label={"Date"}
                      helperText="Used to sort lines"
                      validate={[required()]}
                      fullWidth
                    />
                    <NumberInput
                      min={"0.01"}
                      step={"0.01"}
                      label="Rate/hr."
                      source="hourlyRate"
                      helperText="Hourly rate"
                      validate={[required()]}
                      fullWidth
                    />
                    <NumberInput
                      min={"0"}
                      step={"0.01"}
                      label="VAT%"
                      source="VAT"
                      helperText="VAT percentage"
                      validate={[required()]}
                      fullWidth
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </Box>
            </Box>
          </CardContent>
          <Toolbar />
        </Card>
      </Form>
    </Box>
  ) : null;
};

const InvoiceEdit = () => {
  return (
    <EditBase
      mutationMode="pessimistic"
      transform={(data) => ({
        ...data,
      })}
    >
      <InvoiceEditContent />
    </EditBase>
  );
};

export { InvoiceEdit };
