import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Toolbar,
  AppBar,
  Box,
  ButtonGroup,
  Button,
  MenuItem,
  Select,
  Divider,
  useMediaQuery,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { push as ResponsiveMenu } from "react-burger-menu";
import { Link, useMatch } from "react-router-dom";
import {
  UserMenu,
  Logout,
  LoadingIndicator,
  useTranslate,
  usePermissions,
  ToggleThemeButton,
  useGetIdentity,
  useLocaleState,
} from "react-admin";
import inMemoryJWT from "../Providers/inMemoryJWT";
import {
  AccountCircle,
  SwapHoriz,
  Warning,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.appBar,
    backgroundImage: "unset",
  },
  logo: {
    width: 48.4375,
    height: 42.6875,
    marginTop: "0.2em",
    marginRight: "0.5em",
  },
  title: {
    color: "white",
    lineHeight: "0",
  },
  impersonationButton: {
    color: "white",
    marginRight: "1em",
  },
  themeButton: {
    color: "white",
    marginRight: "1em",
  },
  languageMenu: {
    color: "white",
    "& svg": {
      color: "white",
    },
    marginRight: "1em",
  },
  impersonatedUserMenu: {
    color: "orange",
    "& svg": {
      color: "orange",
      fontSize: "1.5rem !important",
    },
  },
  userMenu: {
    "& svg": {
      fontSize: "1.5rem !important",
    },
  },
  userMenuItem: {
    color: theme.palette.text.secondary,
  },
  userMenuIcon: {
    minWidth: theme.spacing(5),
  },
}));

var menuStyles = {
  bmBurgerButton: {
    position: "relative",
    width: "25px",
    height: "20px",
    marginLeft: "1.5em",
  },
  bmBurgerBars: {
    background: "#fafafb",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    top: 0,
    right: 0,
  },
  bmMenu: {
    background: "#373a47",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    top: 0,
    left: 0,
    height: "100%",
  },
};

const LinkButton = (props) => {
  const match = useMatch("/*");
  const currentPath = match?.pathname ?? "/";
  let textColor = "#ccc";

  if (currentPath === "/" && props.to === "/") {
    textColor = "#fff";
  }

  if (props.to !== "/" && currentPath.match(props.to)) {
    textColor = "#fff";
  }

  return (
    <Button
      color="secondary"
      sx={{
        color: textColor,
        borderColor: "#53387e !important",
      }}
      component={Link}
      {...props}
    >
      {props.children}
    </Button>
  );
};

const NavButtons = (props) => {
  const translate = useTranslate();
  const { permissions, isLoading: permissionsLoading } = usePermissions();

  return permissionsLoading ? null : (
    <ButtonGroup variant="text" orientation={props.orientation}>
      <LinkButton to="/">{translate("main_menu.dashboard")}</LinkButton>
      <Divider orientation="vertical" flexItem />
      <LinkButton to="/customers">
        {translate("main_menu.clients")}
      </LinkButton>
      <Divider orientation="vertical" flexItem />
      <LinkButton to="/employees">
        {translate("main_menu.colleagues")}
      </LinkButton>
      {permissions.some((e) => ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)) && (
        <React.Fragment>
          <Divider orientation="vertical" flexItem />
          <LinkButton to="/planned_events">
            {translate("main_menu.planning")}
          </LinkButton>
        </React.Fragment>
      )}
      <Divider orientation="vertical" flexItem />
      <LinkButton to="/event_reports">
        {translate("main_menu.reporting")}
      </LinkButton>
      {permissions.some((e) => ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)) && (
        <React.Fragment>
          <Divider orientation="vertical" flexItem />
          <LinkButton to="/invoice_collections">
            {translate("main_menu.invoices")}
          </LinkButton>
          <Divider orientation="vertical" flexItem />
          <LinkButton to="/payroll_collections">
            {translate("main_menu.payroll_items")}
          </LinkButton>
          <Divider orientation="vertical" flexItem />
          <LinkButton to="/configuration">
            {translate("main_menu.configuration")}
          </LinkButton>
        </React.Fragment>
      )}
    </ButtonGroup>
  );
};

const CustomUserMenu = () => {
  const classes = useStyles();
  const isImpersonated = inMemoryJWT.getImpersonation() ? true : false;
  const className = isImpersonated
    ? classes.impersonatedUserMenu
    : classes.userMenu;
  const icon = isImpersonated ? <Warning /> : <AccountCircle />;
  const translate = useTranslate();
  return (
    <UserMenu className={className} icon={icon} logout={<Logout />}>
      {isImpersonated && (
        <MenuItem
          onClick={() => {
            inMemoryJWT.removeImpersonation();
          }}
        >
          <ListItemIcon>
            <SwapHoriz />
          </ListItemIcon>
          <ListItemText>
            {translate("impersonation.stop")}
          </ListItemText>
        </MenuItem>
      )}
      <Logout />
    </UserMenu>
  );
};

const CustomHeader = ({ userMenu }) => {
  const [locale, setLocale] = useLocaleState();
  const { isLoading: identityLoading } = useGetIdentity();
  const classes = useStyles();
  const otherLocale = locale === "nl" ? "en" : "nl";

  const isBig = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return identityLoading ? null : (
    <AppBar component="nav" className={classes.root}>
      <Toolbar variant="dense">
        <a href="/#/">
          <img className={classes.logo} src={"/logo.svg"} alt="Logo" />
        </a>
        {isBig && <NavButtons sx={{ marginLeft: "2.5em" }} />}
        <Box component="span" sx={{ flex: 1 }} />
        {isBig && (
          <Select
            variant="standard"
            className={classes.languageMenu}
            value={locale}
            onChange={() => setLocale(otherLocale)}
          >
            <MenuItem disabled={locale === "en"} value="en">
              English
            </MenuItem>
            <MenuItem disabled={locale === "nl"} value="nl">
              Nederlands
            </MenuItem>
          </Select>
        )}
        <LoadingIndicator />
        <ToggleThemeButton />
        <CustomUserMenu />
        {!isBig && (
          <ResponsiveMenu
            right
            styles={menuStyles}
            pageWrapId="main-content"
            outerContainerId="outer-container"
          >
            <NavButtons
              orientation="vertical"
              variant="fullWidth"
              sx={{ display: "flex" }}
            />
          </ResponsiveMenu>
        )}
      </Toolbar>
    </AppBar>
  );
};

export { CustomHeader };
