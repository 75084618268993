import { RichTextInput } from "ra-input-rich-text";
import { RichEditorOptions } from "./RichEditorOptions";
import { RichEditorToolbar } from "./RichEditorToolbar";

const RichEditor = ({ size, ...props }) => {
  return (
    <RichTextInput
      editorOptions={RichEditorOptions}
      toolbar={<RichEditorToolbar size={size} />}
      {...props}
    />
  );
};

export { RichEditor };
