import React, { useEffect } from "react";
import { CurrentTimeIndicator as DxCurrentTimeIndicator } from "@devexpress/dx-react-scheduler-material-ui";

const CurrentTimeIndicator = () => {
  useEffect(() => {
    let el = document.querySelector("[class*='Indicator-nowIndicator']");
    if (el) {
      el.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
  }, []);
  return <DxCurrentTimeIndicator />;
};

export { CurrentTimeIndicator };
