import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogContent,
  Button,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Loading,
  useGetIdentity,
  useGetList,
  LinearProgress,
  ResourceContextProvider,
  useTranslate,
} from "react-admin";
import { Event, AddBox, EventNote, Contacts } from "@mui/icons-material";
import { EventScheduler } from "..";
import { CustomerList } from "../../Resources/Customers/CustomerList";
import { EventReportShowContent } from "../../Resources/EventReports/EventReportShow";
import { PlannedEventList } from "../../Resources/PlannedEvents/PlannedEventList";

const useStyles = makeStyles((theme) => ({
  listItem: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  card: {
    minHeight: 100,
  },
  eventsCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 600,
    maxWidth: "93vw",
    paddingTop: "0",
    marginTop: "0",
    marginBottom: "2rem",
  },
  missingReportsCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  clientsCard: {
    padding: 0,
    "& ul": {
      padding: 0,
    },
    "&:last-child": {
      padding: 0,
    },
  },
}));

const MyPlannedEventsList = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const classes = useStyles();
  const translate = useTranslate();

  if (identityLoading) {
    return (
      <React.Fragment>
        <Box ml={1}>
          <Typography gutterBottom variant="h5" color="textSecondary">
            <Event /> {translate("dashboard.planning.title")}
          </Typography>
        </Box>
        <Box className={classes.eventsCard}>
          <Loading />
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Box ml={1}>
        <Typography gutterBottom variant="h5" color="textSecondary">
          <Event /> {translate("dashboard.planning.title")}
        </Typography>
      </Box>
      <Box className={classes.eventsCard}>
        <ResourceContextProvider value="planned_events">
          <PlannedEventList
            filter={{ employees: identity.id }}
            filters={null}
            actions={null}
            height={600}
            defaultView={EventScheduler.MONTH_VIEW}
            allowEdits={false}
            mainResourceName="customer"
          />
        </ResourceContextProvider>
      </Box>
    </React.Fragment>
  );
};

const MyLatestReportsList = () => {
  const { identity, isLoading: identityLoading } = useGetIdentity();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const translate = useTranslate();

  // Get related EventReports
  const report_filter = identityLoading
    ? { pagination: false }
    : { pagination: false, owner: identity.id };
  const { data, isLoading: dataLoading } = useGetList(
    "event_reports",
    {
      sort: { field: "dateStart", order: "desc" },
      pagination: { pagination: false },
      filter: report_filter,
    },
    {
      enabled: !identityLoading,
    }
  );
  if (identityLoading || dataLoading || (data && data.length === 0)) {
    return (
      <React.Fragment>
        <Typography gutterBottom variant="h5" color="textSecondary">
          <EventNote /> {translate("dashboard.reports.title")}
        </Typography>
        <Card className={classes.card}>
          <CardContent>
            <Box
              display="flex"
              height={"60px"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {data && data.length === 0 ? (
                <Typography component="h5" variant="h5" color="textSecondary">
                  {translate("dashboard.reports.not_found")}
                </Typography>
              ) : (
                <LinearProgress />
              )}
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Typography gutterBottom variant="h5" color="textSecondary">
        <EventNote /> {translate("dashboard.reports.title")}
      </Typography>
      {data.length > 5 && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => setOpen(true)}
            sx={{
              position: "absolute",
              transform: "translateY(-2.75rem)",
            }}
          >
            {translate("dashboard.reports.show_all")}
          </Button>
        </Box>
      )}
      {dataLoading ? (
        <Box
          display="flex"
          height={"60px"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <LinearProgress />
        </Box>
      ) : (
        data.slice(0, 5).map((report) => {
          return (
            <React.Fragment>
              <Card sx={{ marginBottom: "1em" }}>
                <CardContent>
                  <EventReportShowContent
                    record={report}
                    showBy={false}
                    showCreatedUpdated={false}
                  />
                  <Divider />
                </CardContent>
              </Card>
            </React.Fragment>
          );
        })
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Typography gutterBottom variant="h5" color="textSecondary">
            <EventNote /> {translate("dashboard.reports.dialog_title")}
          </Typography>
          {data.map((report) => {
            return (
              <React.Fragment>
                <Card sx={{ marginBottom: "1em" }}>
                  <CardContent>
                    <EventReportShowContent record={report} showBy={false} />
                    <Divider />
                  </CardContent>
                </Card>
              </React.Fragment>
            );
          })}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const MyCustomersList = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const classes = useStyles();
  const translate = useTranslate();

  if (identityLoading) {
    return (
      <React.Fragment>
        <Typography gutterBottom variant="h5" color="textSecondary">
          <Contacts /> {translate("dashboard.clients.title")}
        </Typography>
        <Card className={classes.card}>
          <CardContent>
            <Box
              display="flex"
              height={"60px"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <LinearProgress />
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Typography gutterBottom variant="h5" color="textSecondary">
        <Contacts /> {translate("dashboard.clients.title")}
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.clientsCard}>
          <ResourceContextProvider value={"customers"}>
            <CustomerList
              filter={{ employees: identity.id, isActive: true }}
              filters={null}
              actions={null}
              aside={null}
              tabbed={false}
            />
          </ResourceContextProvider>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

const MyMissingReportsList = () => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <React.Fragment>
      <Typography gutterBottom variant="h5" color="textSecondary">
        <AddBox /> {translate("dashboard.missing_reports.title")}
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.missingReportsCard}>
          <Box
            display="flex"
            height={"60px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography component="h5" variant="h5" color="textSecondary">
              {translate("dashboard.missing_reports.in_development")}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

const Dashboard = () => {
  const { isLoading: identityLoading } = useGetIdentity();
  const isBig = useMediaQuery((theme) => theme.breakpoints.up("md"));

  if (identityLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {isBig ? (
        <React.Fragment>
          <Box sx={{ display: "flex", marginTop: "2em", alignItems: "center" }}>
            <Box sx={{ flex: 0.1 }} />
            <Box sx={{ flex: 1, maxWidth: "100vw" }}>
              <MyPlannedEventsList />
            </Box>
            <Box sx={{ flex: 0.1 }} />
          </Box>
          <Box sx={{ display: "flex", marginTop: "2em" }}>
            <Box sx={{ flex: 0.2 }} />
            <Box sx={{ flex: 1, marginRight: "1em" }}>
              <MyMissingReportsList />
            </Box>
            <Box sx={{ flex: 1.1, marginRight: "1em" }}>
              <MyLatestReportsList />
            </Box>
            <Box sx={{ flex: 1 }}>
              <MyCustomersList />
            </Box>
            <Box sx={{ flex: 0.2 }} />
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: "flex", marginTop: "2em", alignItems: "center" }}>
            <Box sx={{ flex: 0.1 }} />
            <Box sx={{ flex: 1, maxWidth: "100vw" }}>
              <MyPlannedEventsList />
            </Box>
            <Box sx={{ flex: 0.1 }} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                flex: 1,
                marginTop: "2em",
                marginLeft: "2em",
                marginRight: "2em",
              }}
            >
              <MyMissingReportsList />
            </Box>
            <Box
              sx={{
                flex: 1,
                marginTop: "2em",
                marginLeft: "2em",
                marginRight: "2em",
              }}
            >
              <MyLatestReportsList />
            </Box>
            <Box
              sx={{
                flex: 1,
                marginTop: "2em",
                marginLeft: "2em",
                marginRight: "2em",
                marginBottom: "2em",
              }}
            >
              <MyCustomersList />
            </Box>
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export { Dashboard };
