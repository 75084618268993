import * as React from "react";
import { makeStyles } from "@mui/styles";
import {
  TopToolbar,
  // FilterButton,
  ExportButton,
  CreateButton,
  useGetIdentity,
  LinearProgress,
} from "react-admin";

const useCustomerParameterListActionsStyles = makeStyles((theme) => ({
  createButton: {
    marginLeft: theme.spacing(2),
  },
}));

const CustomerParameterListActions = () => {
  const { data: identity, isLoading } = useGetIdentity();
  const classes = useCustomerParameterListActionsStyles();

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <TopToolbar>
      {/* <FilterButton /> */}
      {identity.roles.some((e) =>
        ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
      ) && (
        <React.Fragment>
          <ExportButton />
          <CreateButton
            className={classes.createButton}
            basePath="/customer_parameters"
            variant="contained"
            label="Add CustomerParameter"
          />
        </React.Fragment>
      )}
    </TopToolbar>
  );
};

export { CustomerParameterListActions };
