import { CustomerList } from "./CustomerList";
import { CustomerShow } from "./CustomerShow";
import { CustomerCreate } from "./CustomerCreate";
import { CustomerEdit } from "./CustomerEdit";

const Customers = {
  list: CustomerList,
  show: CustomerShow,
  create: CustomerCreate,
  edit: CustomerEdit,
};

export { Customers };
