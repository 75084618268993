import * as React from "react";
import {
  CreateBase,
  ReferenceInput,
  SelectInput,
  Form,
  Toolbar,
  required,
  useCreateContext,
  useCreate,
  useNotify,
  useRedirect,
  useGetIdentity,
  useGetOne,
  LinearProgress,
  ArrayInput,
  SimpleFormIterator,
  TimeInput,
  SaveButton,
  DeleteButton,
  useTranslate,
} from "react-admin";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Avatar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useWatch, useFormContext } from "react-hook-form";
import { Note } from "@mui/icons-material";
import { RichEditor } from "../../Components";
import { differenceInMinutes, format, isValid } from "date-fns";
import { timeConvert } from "../../Tools";
import { isEqual } from "lodash";

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField  from '@mui/material/TextField';

const Spacer = () => <Box width={20} component="span" />;

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    width: "60px",
    height: "60px",
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
  formCard: {
    overflow: "initial",
    width: "100%",
  },
  reportTextInput: {
    "& span#comments-label": {
      display: "flex",
    },
    "& div.RaRichTextInput-editorContent > div#comments": {
      minHeight: 200,
    },
  },
  fixedWidthInput: {
    width: "60em",
  },
  formIteratorXSmall: {
    "& li": {
      "& section": {
        flexDirection: "row !important",
        flexWrap: "wrap",
        "& div.ra-input": {
          width: "45%",
          marginRight: "auto",
          "& div": {
            minWidth: 0,
            "& p": {
              display: "none",
            },
          },
        },
      },
    },
  },
  formIterator: {
    "& li": {
      "& section": {
        flexDirection: "row !important",
        width: "100%",
        "& div.ra-input": {
          marginRight: "10px",
          "& div": {
            minWidth: 0,
          },
        },
      },
    },
  },
}));

const EventTypeInput = ({ width }) => {
  const filter = {};
  const values = useWatch();
  if (values.customer) {
    filter.customers = values.customer.split("/")[3];
    filter.billable = true;
  } else {
    filter.billable = false;
  }
  return (
    <Box style={{ width: width }}>
      <ReferenceInput
        source="eventType"
        reference="event_types"
        resettable
        filter={filter}
      >
        <SelectInput
          optionText="name"
          label="configuration.services.title"
          helperText="reporting.form.help.event_type"
          translateChoice={false}
          fullWidth
          resettable
          validate={[required()]}
        />
      </ReferenceInput>
    </Box>
  );
};

const CustomerInput = ({ width }) => {
  const filter = {};
  const values = useWatch();

  if (values.owner) {
    filter.employees = values.owner.split("/")[3];
  }

  if (values.eventType) {
    filter.eventTypes = values.eventType.split("/")[3];
  }

  return (
    <Box style={{ width: width }}>
      <ReferenceInput
        source="customer"
        reference="customers"
        resettable
        filter={filter}
      >
        <SelectInput
          optionText="fullName"
          label="clients.show.client"
          helperText="reporting.form.help.customer"
          translateChoice={false}
          resettable
          fullWidth
        />
      </ReferenceInput>
    </Box>
  );
};

const OwnerInput = ({ width }) => {
  const filter = {};
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const values = useWatch();

  if (identityLoading) {
    return (
      <Box style={{ width: width }}>
        <LinearProgress />
      </Box>
    );
  }

  if (values.customer) {
    filter.customers = values.customer.split("/")[3];
  }

  const iri = `/api/employees/${identity.id}`;

  return (
    <Box style={{ width: width }}>
      <ReferenceInput
        source="owner"
        reference="employees"
        resettable
        filter={filter}
      >
        <SelectInput
          optionText="fullName"
          label="employees.show.employee"
          helperText="reporting.form.help.owner"
          translateChoice={false}
          fullWidth
          defaultValue={iri}
          validate={[required()]}
          resettable
          disabled={
            !identity.roles.some((e) =>
              ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
            )
          }
        />
      </ReferenceInput>
    </Box>
  );
};

const ShowParsedTime = () => {
  const values = useWatch();
  const translate = useTranslate();

  const [ hrs, setHrs ] = React.useState(null);
  const [ min, setMin ] = React.useState(null);
  const [ color, setColor ] = React.useState(null);

  React.useEffect(() => {
    if (values.dateStart && values.dateEnd) {
      let totalMinutes = differenceInMinutes(values.dateEnd, values.dateStart);
      setHrs(timeConvert(totalMinutes)[0]);
      setMin(timeConvert(totalMinutes)[1]);
      setColor(totalMinutes > 0 && totalMinutes <= 1440 ? "black" : "red");
    }
  }, [values.dateStart, values.dateEnd, setHrs, setMin, setColor])

  if (values.dateStart && values.dateEnd) {
    return min > 0 ? (
      <Typography color={color}>
        {`${hrs} ${translate("reporting.form.hours")}, ${min} ${translate(
          "reporting.form.minutes"
        )}`}
      </Typography>
    ) : (
      <Typography color={color}>
        {`${hrs} ${translate("reporting.form.hours")}`}
      </Typography>
    );
  }

  return null;
};

const findMatchingRanges = (onCallRanges, eventStart, eventEnd) => {
  let matchingRanges = [];
  onCallRanges.forEach((onCallRange) => {
    // Skip onCallRanges that specify endDay 2 when the EventReport
    // starts and ends on the same day (ie. 2 is not an option)
    if (
      onCallRange.endDay === "2" &&
      format(eventStart, "dd-MM-yyyy") === format(eventEnd, "dd-MM-yyyy")
    ) {
      return;
    }

    // Split 08:00 into hour 08 and minute 00 etc
    let startHour = String(onCallRange.startTime).split(":")[0];
    let startMinute = String(onCallRange.startTime).split(":")[1];
    let endHour = String(onCallRange.endTime).split(":")[0];
    let endMinute = String(onCallRange.endTime).split(":")[1];
    let range = {};

    range.startDay = onCallRange.startDay;
    range.endDay = onCallRange.endDay;

    // get onCallRange start / end as Date objects
    if (range.startDay === "1") {
      if (typeof onCallRange.startTime === "string") {
        range.startTime = new Date(eventStart);
        range.startTime.setHours(startHour, startMinute, 0, 0);
      }
    } else {
      if (typeof onCallRange.startTime === "string") {
        range.startTime = new Date(eventEnd);
        range.startTime.setHours(startHour, startMinute, 0, 0);
      }
    }
    if (range.endDay === "1") {
      if (typeof onCallRange.endTime === "string") {
        range.endTime = new Date(eventStart);
        range.endTime.setHours(endHour, endMinute, 0, 0);
      }
    } else {
      if (typeof onCallRange.endTime === "string") {
        range.endTime = new Date(eventEnd);
        range.endTime.setHours(endHour, endMinute, 0, 0);
      }
    }

    if (
      range.startTime >= eventStart &&
      range.startTime < eventEnd &&
      range.endTime > eventStart &&
      range.endTime <= eventEnd
    ) {
      matchingRanges.push(range);
    }
  });

  return matchingRanges;
};

const OnCallRangesInput = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const values = useWatch();
  const { setValue } = useFormContext();
  const [choices, setChoices] = React.useState([]);
  const [matchingRanges, setMatchingRanges] = React.useState([]);
  const [eventStartWas, setEventStartWas] = React.useState(values.dateStart);
  const [eventEndWas, setEventEndWas] = React.useState(values.dateEnd);
  const { data, isLoading } = useGetOne(
    "event_types",
    { id: values.eventType },
    {
      enabled: values.eventType ? true : false,
    }
  );

  React.useEffect(() => {
    if (data?.onCall) {
      // Do nothing if we have no dates set yet
      if (
        values.dateStart === undefined
        || values.dateEnd === undefined
        || values.dateStart === null
        || values.dateEnd === null
        || !isValid(values.dateStart)
        || !isValid(values.dateEnd)
        ) {
        return;
      }

      // Get eventReport start / end as Date objects
      let eventStart = new Date(values.dateStart);
      let eventEnd = new Date(values.dateEnd);

      if (data?.defaultOnCallRanges) {
        // For some reason this kept changing state so we're caching
        // the value in state and only setting the value once every time
        // the dateStart or dateEnd changes
        let newMatchingRanges = findMatchingRanges(
          data.defaultOnCallRanges,
          eventStart,
          eventEnd
        );

        // If we have no values set, set them
        if (values.onCallRanges?.length === 0) {
          setMatchingRanges(newMatchingRanges);
          setValue("onCallRanges", newMatchingRanges);
          setEventStartWas(values.dateStart);
          setEventEndWas(values.dateEnd);
        }

        // Maybe update the form values when the dates change
        else if (
          !isEqual(eventStartWas, values.dateStart) ||
          !isEqual(eventEndWas, values.dateEnd)
        ) {
          // Ensure we only update if the user didn't manually change them
          if (
            isEqual(values.onCallRanges, matchingRanges) &&
            !isEqual(matchingRanges, newMatchingRanges)
          ) {
            global.console.log("setting value");
            setMatchingRanges(newMatchingRanges);
            setValue("onCallRanges", newMatchingRanges);
          }

          // Track the date changes
          setEventStartWas(values.dateStart);
          setEventEndWas(values.dateEnd);
        }
      }

      setChoices(
        format(eventStart, "dd-MM-yyyy") === format(eventEnd, "dd-MM-yyyy")
          ? [
              {
                id: "1",
                name: format(eventStart, "dd-MM-yyyy"),
              },
            ]
          : [
              {
                id: "1",
                name: format(eventStart, "dd-MM-yyyy"),
              },
              {
                id: "2",
                name: format(eventEnd, "dd-MM-yyyy"),
              },
            ]
      );
    }
  }, [
    data?.defaultOnCallRanges,
    data?.onCall,
    eventEndWas,
    eventStartWas,
    matchingRanges,
    setValue,
    values.dateEnd,
    values.dateStart,
    values.onCallRanges,
  ]);

  const isBig = useMediaQuery((theme) => theme.breakpoints.up("md"));

  if (typeof values.eventType == "undefined") {
    return null;
  }

  if (!data?.onCall) {
    return null;
  }

  if (isLoading) {
    return (
      <Box mt={2} mb={2} display="flex" justifyContent="center">
        <LinearProgress />
      </Box>
    );
  }

  if (data.onCall === true) {
    if (values.dateStart === undefined || values.dateEnd === undefined) {
      return (
        <React.Fragment>
          <Divider variant="middle" />
          <Box mt={2} display="flex" justifyContent="center">
            <Typography>
              {translate("configuration.services.on_call_active.title")}
            </Typography>
          </Box>
          <Box mt={2} mb={2} display="flex" justifyContent="center">
            <Typography variant="h6">
              {translate("reporting.form.help.enter_dates")}
            </Typography>
          </Box>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Divider variant="middle" />
        <Box mt={2} display="flex" justifyContent="center">
          <Typography>
            {translate("configuration.services.on_call_active.title")}
          </Typography>
        </Box>
        <ArrayInput source="onCallRanges" label={false} validate={[required()]}>
          <SimpleFormIterator
            className={
              isBig ? classes.formIterator : classes.formIteratorXSmall
            }
          >
            <SelectInput
              label="configuration.services.on_call_active.start_day"
              source="startDay"
              helperText="configuration.services.form.help.on_call.start_day"
              allowEmpty={false}
              choices={choices}
              defaultValue={"1"}
              fullWidth
              validate={[required()]}
            />
            <TimeInput
              label="configuration.services.on_call_active.start_time"
              source="startTime"
              helperText="configuration.services.form.help.on_call.start_time"
              options={{ format: "HH:mm" }}
              fullWidth
              validate={[required()]}
            />
            <SelectInput
              label="configuration.services.on_call_active.end_day"
              source="endDay"
              helperText="configuration.services.form.help.on_call.end_day"
              allowEmpty={false}
              choices={choices}
              defaultValue={"1"}
              fullWidth
              validate={[required()]}
            />
            <TimeInput
              label="configuration.services.on_call_active.end_time"
              source="endTime"
              helperText="configuration.services.form.help.on_call.end_time"
              options={{ format: "HH:mm" }}
              fullWidth
              validate={[required()]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </React.Fragment>
    );
  }

  return null;
};

const DateStartInput = () => {
  const translate = useTranslate();
  const values = useWatch();
  const { setValue } = useFormContext();

  return (
    <DateTimePicker
      renderInput={(params) => <TextField {...params} helperText={translate("reporting.form.help.start")} fullWidth />}
      views={['year', 'month', 'day', 'hours', 'minutes']}
      label={translate("reporting.start")}
      ampm={false}
      disableFuture
      value={values.dateStart ?? null}
      onChange={(newValue) => setValue("dateStart", newValue)}
      validate={[required()]}
    />
  );

}

const DateEndInput = () => {
  const translate = useTranslate();
  const values = useWatch();
  const { setValue } = useFormContext();

  return (
    <DateTimePicker
      renderInput={(params) => <TextField {...params} helperText={translate("reporting.form.help.end")} fullWidth />}
      views={['year', 'month', 'day', 'hours', 'minutes']}
      label={translate("reporting.end")}
      ampm={false}
      disableFuture
      value={values.dateEnd ?? null}
      onChange={(newValue) => setValue("dateEnd", newValue)}
      validate={[required()]}
    />
  );
}

const transformValues = async (values) => {
  values.onCallRanges = values.onCallRanges
    ? values.onCallRanges.map((item) => {
        item.startTime = format(new Date(item.startTime), "HH:mm");
        item.endTime = format(new Date(item.endTime), "HH:mm");
        return item;
      })
    : [];

  return values;
};

const EventReportCreateContent = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();
  const { record } = useCreateContext();
  const [create] = useCreate();
  const save = React.useCallback(
    async (values) => {
      await transformValues(values);
      try {
        await create(
          "event_reports",
          { data: values },
          { returnPromise: true }
        ).then((response) => {
          const eid = encodeURIComponent(response.id);
          redirect(`/event_reports/${eid}/show`);
        });
      } catch (error) {
        let errors = {};
        error.body[0]["http://www.w3.org/ns/hydra/core#description"][0][
          "@value"
        ]
          .split(/\n/)
          .forEach((e) => {
            notify(e, { type: "warning" });
            const err = e.split(": ");
            errors[err[0]] = err[1];
          });
        notify("ra.notification.create.failure", {
          type: "warning",
        });
        return errors;
      }
    },
    [create, notify, redirect]
  );

  const isBig = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        display: "flex",
        marginTop: 2,
        width: "100%",
        maxWidth: 800,
        alignSelf: "center",
        "& form": {
          width: "100%",
        },
      }}
    >
      <Form record={record} onSubmit={save}>
        <Card className={classes.formCard}>
          <CardContent>
            <Box>
              <Box display="flex">
                <Box flex={1}></Box>
                <Box flex={1} display="flex" justifyContent="center">
                  <Avatar className={classes.avatarContainer}>
                    <Note className={classes.avatar} />
                  </Avatar>
                </Box>
                <Box flex={1}></Box>
              </Box>
              <Box mt={1} display="flex" justifyContent="center">
                <Typography>{translate("reporting.form.title")}</Typography>
              </Box>
              <Box display="flex">
                <DateStartInput />
                <Spacer />
                <DateEndInput />
              </Box>
              <Divider variant="middle" />
              <Box className={classes.reportTextInput}>
                <RichEditor
                  label={false}
                  source="comments"
                  helperText="reporting.form.help.comments"
                  validate={[required()]}
                  sx={{
                    marginTop: 1,
                  }}
                />
              </Box>
              <Box>
                <OnCallRangesInput />
                <Divider variant="middle" />
                <Box mt={1} display="flex" justifyContent="center">
                  <Typography>
                    {translate("reporting.form.relationships")}
                  </Typography>
                </Box>
                {isBig ? (
                  <Box display="flex">
                    <CustomerInput width="33%" />
                    <Spacer />
                    <EventTypeInput width="33%" />
                    <Spacer />
                    <OwnerInput width="33%" />
                  </Box>
                ) : (
                  <Box>
                    <CustomerInput width="100%" />
                    <Spacer />
                    <EventTypeInput width="100%" />
                    <Spacer />
                    <OwnerInput width="100%" />
                  </Box>
                )}
              </Box>
            </Box>
          </CardContent>
          <Toolbar>
            <SaveButton sx={{ marginRight: "1em" }} />
            <ShowParsedTime />
            <DeleteButton />
          </Toolbar>
        </Card>
      </Form>
    </Box>
  );
};

const EventReportCreate = () => {
  return (
    <CreateBase
      transform={(data) => ({
        ...data,
      })}
    >
      <EventReportCreateContent />
    </CreateBase>
  );
};

export { EventReportCreate };
