import { EventReportList } from "./EventReportList";
import { EventReportShow } from "./EventReportShow";
import { EventReportCreate } from "./EventReportCreate";
import { EventReportEdit } from "./EventReportEdit";

const EventReports = {
  list: EventReportList,
  show: EventReportShow,
  create: EventReportCreate,
  edit: EventReportEdit,
};

export { EventReports };
