import { PayrollCollectionList } from "./PayrollCollectionList";

const PayrollCollections = {
  list: PayrollCollectionList,
  show: false,
  create: false,
  edit: false,
};

export { PayrollCollections };
