import { ProjectionPayrollCollectionList } from "./ProjectionPayrollCollectionList";

const ProjectionPayrollCollections = {
  list: ProjectionPayrollCollectionList,
  show: false,
  create: false,
  edit: false,
};

export { ProjectionPayrollCollections };
