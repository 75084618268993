import * as React from "react";
import {
  TopToolbar,
  ExportButton,
  useGetIdentity,
  LinearProgress,
} from "react-admin";

const InvoiceCollectionListActions = () => {
  const { data: identity, isLoading } = useGetIdentity();

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <TopToolbar>
      {identity.roles.some((e) =>
        ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
      ) && (
        <React.Fragment>
          <ExportButton />
        </React.Fragment>
      )}
    </TopToolbar>
  );
};

export { InvoiceCollectionListActions };
