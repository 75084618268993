import * as React from "react";

import {
  Grid,
  Divider,
  Typography,
  Avatar,
  Tabs,
  Tab,
  Box,
  CardContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Person, Business } from "@mui/icons-material";
import ContentAdd from "@mui/icons-material/Add";
import {
  TextField as RaTextField,
  FunctionField,
  Show,
  SimpleShowLayout,
  useGetIdentity,
  Loading,
  Button,
  TextField,
  ResourceContextProvider,
  useRecordContext,
  useTranslate,
  LinearProgress,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { Link } from "react-router-dom";

import { ShowGuesser } from "@api-platform/admin";
import { EmployeeList } from "../Employees/EmployeeList";
import { EventReportList } from "../EventReports/EventReportList";
import { InvoiceList } from "../Invoices/InvoiceList";
import { CustomerParameterList } from "../CustomerParameters/CustomerParameterList";
import { EventScheduler, TabPanel } from "../../Components";
import { PlannedEventList } from "../PlannedEvents/PlannedEventList";
import { EventReportListActions } from "../EventReports/EventReportListActions";

const useAsideFilterStyles = makeStyles((theme) => ({
  root: {
    display: "none",
    order: -1,
    minWidth: "352px",
    marginLeft: "10px",
    marginRight: "24px",
    "& .RaShow-noActions": {
      marginTop: 0,
      position: "sticky",
      top: "65px",
    },
    [theme.breakpoints.up("lg")]: {
      display: "initial",
    },
  },
  avatarContainer: {
    width: "60px",
    height: "60px",
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      flexDirection: "row",
      "& button": {
        flex: 1,
      },
    },
  },
}));

/**
 * Left bar area
 */
const Aside = () => {
  const translate = useTranslate();
  const classes = useAsideFilterStyles();
  const [tabstate, setValue] = React.useState(0);
  const record = useRecordContext();

  if (!record) {
    return (
      <Box mt={10} mb={5} className={classes.root}>
        <LinearProgress />
      </Box>
    );
  }

  const changeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Show actions={false} className={classes.root}>
      <CardContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Avatar className={classes.avatarContainer}>
            <FunctionField
              render={(record) => {
                return record.isCompany ? (
                  <Business className={classes.avatar} />
                ) : (
                  <Person className={classes.avatar} />
                );
              }}
            />
          </Avatar>
          <Box mt={1}>
            <Typography align="center" variant="h5">
              {record.isCompany
                ? record.name
                : `${record.name.charAt(0)}. ${record.lastName}`}
            </Typography>
          </Box>
        </Grid>
        <br />

        <Divider />
        <Tabs
          value={tabstate}
          textColor="primary"
          indicatorColor="primary"
          onChange={changeTab}
          className={classes.tabs}
        >
          <Tab label={translate("contact.details")} />
          <Tab label={translate("contact.address")} />
        </Tabs>

        <TabPanel value={tabstate} index={0}>
          <Show actions={false}>
            <SimpleShowLayout>
              {record?.representativeName ? (
                <Typography>{translate("clients.show.client")}</Typography>
              ) : null}
              <TextField source={"name"} label={translate("contact.name")} />
              <TextField
                source={"lastName"}
                label={translate("contact.lastName")}
              />
              {record?.legalNames ? (
                <TextField
                  source={"legalFullName"}
                  label={translate("contact.legalName")}
                />
              ) : null}
              {record?.emailAddress ? (
                <TextField
                  source={"emailAddress"}
                  label={translate("contact.email_address")}
                />
              ) : null}
              {record?.phoneNumber ? (
                <TextField
                  source={"phoneNumber"}
                  label={translate("contact.phone_number")}
                />
              ) : null}
              {record?.socialRegistrationNumber ? (
                <TextField
                  source={"socialRegistrationNumber"}
                  label={translate("contact.social_registration_number")}
                />
              ) : null}
            </SimpleShowLayout>
          </Show>

          {record?.representativeName ? (
            <Show actions={false} sx={{ mt: "1em" }}>
              <SimpleShowLayout>
                <Typography>{translate("contact.representative")}</Typography>
                <TextField
                  source={"representativeName"}
                  label={translate("contact.name")}
                />
                {record?.representativeEmailAddress ? (
                  <TextField
                    source={"representativeEmailAddress"}
                    label={translate("contact.email_address")}
                  />
                ) : null}
                {record?.representativePhoneNumber ? (
                  <TextField
                    source={"representativePhoneNumber"}
                    label={translate("contact.phone_number")}
                  />
                ) : null}
              </SimpleShowLayout>
            </Show>
          ) : null}
        </TabPanel>

        <TabPanel value={tabstate} index={1}>
          <Show actions={false}>
            <SimpleShowLayout>
              <RaTextField label="Street Name" source={"address.streetName"} />
              <RaTextField
                label="House Number"
                source={"address.streetNumber"}
              />
              <RaTextField label="Postal Code" source={"address.postalCode"} />
              <RaTextField label="City" source={"address.city"} />
            </SimpleShowLayout>
          </Show>
        </TabPanel>
      </CardContent>
    </Show>
  );
};

const ShowPlannedEvents = () => {
  const record = useRecordContext();

  if (!record) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  const cid = record.id.split("/")[3];

  // Find viewport height
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );

  return (
    <ResourceContextProvider value="planned_events">
      <PlannedEventList
        filter={{ customer: cid }}
        filters={false}
        actions={false}
        disableSyncWithLocation
        height={vh * 0.76}
        defaultView={EventScheduler.WEEK_VIEW}
        allowEdits={false}
        mainResourceName="employee"
      />
    </ResourceContextProvider>
  );
};

const ShowEventReports = () => {
  const record = useRecordContext();

  if (!record) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  const cid = record.id.split("/")[3];

  return (
    <ResourceContextProvider value={"event_reports"}>
      <EventReportList
        sx={{
          "& div.RaList-actions": {
            '&:not(:has(div[class*="filter-field"]))': {
              marginTop: "-64px",
              width: "25%",
              alignSelf: "flex-end",
            },
          },
        }}
        filter={{ customer: cid }}
        showCustomer={false}
        showInvoices={false}
        filters={[
          <ReferenceInput
            label="employees.show.employee"
            source="owner"
            reference="employees"
            filter={{ owner: cid }}
          >
            <SelectInput
              label="employees.show.employee"
              optionText="fullName"
            />
          </ReferenceInput>,
        ]}
        actions={
          <EventReportListActions showExport={false} showCreate={false} />
        }
        disableSyncWithLocation
        allowSelect={false}
      />
    </ResourceContextProvider>
  );
};

const ShowEmployees = () => {
  const record = useRecordContext();

  if (!record) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  const cid = record.id.split("/")[3];

  return (
    <ResourceContextProvider value={"employees"}>
      <EmployeeList
        filter={{ customers: cid, isActive: true }}
        filters={null}
        actions={null}
        aside={null}
        tabbed={false}
      />
    </ResourceContextProvider>
  );
};

const ShowInvoices = () => {
  const record = useRecordContext();

  if (!record) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  const cid = record.id.split("/")[3];

  return (
    <ResourceContextProvider value={"invoices"}>
      <InvoiceList
        filter={{ customer: cid }}
        showCustomer={false}
        filters={false}
        actions={false}
        allowSelect={false}
      />
    </ResourceContextProvider>
  );
};

const ShowCustomPrices = () => {
  const translate = useTranslate();
  const record = useRecordContext();

  if (!record) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  const cid = record.id.split("/")[3];

  return (
    <ResourceContextProvider value={"customer_parameters"}>
      <CustomerParameterList
        sx={{
          "& div.RaList-actions": {
            '&:not(:has(div[class*="filter-field"]))': {
              marginTop: "-37.5px",
              width: "30%",
              alignSelf: "flex-end",
              "& a.MuiButton-contained": {
                marginBottom: "10px",
                marginRight: "5px",
              },
            },
          },
        }}
        filter={{ customer: cid }}
        showCustomer={false}
        filters={null}
        actions={
          <Button
            variant="contained"
            component={Link}
            to={{
              pathname: "/customer_parameters/create",
              state: { record: { customer: record.id } },
            }}
            label={translate("clients.custom_pricing.add")}
          >
            <ContentAdd />
          </Button>
        }
        allowSelect={false}
      />
    </ResourceContextProvider>
  );
};

/**
 * Main (tabbed) content area
 */
const CustomerShow = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const [tabstate, setValue] = React.useState(0);
  const changeTab = (event, newValue) => {
    setValue(newValue);
  };
  const translate = useTranslate();

  if (identityLoading) {
    return (
      <Box mt={10} mb={5}>
        <Loading />
      </Box>
    );
  }

  let actions = false;
  identity.roles.some((e) => ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)) &&
    (actions = undefined);

  return (
    <Show aside={<Aside />} actions={actions}>
      <Tabs
        value={tabstate}
        textColor="primary"
        indicatorColor="primary"
        onChange={changeTab}
      >
        <Tab label={translate("clients.show.planning")} />
        <Tab label={translate("clients.show.reports")} />
        <Tab label={translate("clients.show.team")} />
        {identity.roles.some((e) =>
          ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
        ) && <Tab label={translate("clients.show.invoices")} />}
        {identity.roles.some((e) =>
          ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
        ) && <Tab label={translate("clients.custom_pricing.title")} />}
        {identity.roles.some((e) => ["ROLE_ADMIN"].includes(e)) && (
          <Tab label="Raw" />
        )}
      </Tabs>
      <Divider />

      <TabPanel value={tabstate} index={0}>
        <ShowPlannedEvents />
      </TabPanel>

      <TabPanel value={tabstate} index={1}>
        <ShowEventReports />
      </TabPanel>

      <TabPanel value={tabstate} index={2}>
        <ShowEmployees />
      </TabPanel>

      {identity.roles.some((e) =>
        ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
      ) && (
        <TabPanel value={tabstate} index={3}>
          <ShowInvoices />
        </TabPanel>
      )}

      {identity.roles.some((e) =>
        ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
      ) && (
        <TabPanel value={tabstate} index={4}>
          <ShowCustomPrices />
        </TabPanel>
      )}

      {identity.roles.some((e) => ["ROLE_ADMIN"].includes(e)) && (
        <TabPanel value={tabstate} index={5}>
          <ShowGuesser actions={false} />
        </TabPanel>
      )}
    </Show>
  );
};

export { CustomerShow };
