import { ProjectionInvoiceCollectionList } from "./ProjectionInvoiceCollectionList";

const ProjectionInvoiceCollections = {
  list: ProjectionInvoiceCollectionList,
  show: false,
  create: false,
  edit: false,
};

export { ProjectionInvoiceCollections };
