import merge from "lodash/merge";
import dutchMessages from "ra-language-dutch";

const Dutch = merge(dutchMessages, {
  ra: {
    notification: {
      application_update_available: "Er is een nieuwe versie beschikbaar.",
      create: {
        failure: "Het object kon niet worden aangemaakt vanwege een fout.",
      },
      edit: {
        failure: "Het object kon niet worden aangepast vanwege een fout.",
      },
      planning: {
        create: {
          success: "Afspraak ingepland.",
          failure:
            "De afspraak kon niet worden ingepland vanwege een fout: %{err}",
        },
        update: {
          success: "Afspraak aangepast.",
          failure:
            "De afspraak kon niet worden aangepast vanwege een fout: %{err}",
        },
      },
    },
    action: {
      update_application: "Updaten"
      // list: '',
      // show: '',
      // edit: 'Aanpassen',
      // create: 'Aanmaken',
      // delete: 'Verwijderen',
      // save: 'Opslaan',
      // cancel: 'Annuleren',
    },
    auth: {
      username: "Gebruikersnaam",
      password: "Wachtwoord",
    },
  },
  messages: {
    none: "Geen",
    today: "Vandaag",
    day: "Dag",
    week: "Week",
    month: "Maand",
  },
  impersonation: {
    start: "Impersonate",
    stop: "Stop Impersonation",
  },
  contact: {
    details: "Contactgegevens",
    address: "Adres",
    representative: "Vertegenwoordiger",
    name: "Naam",
    lastName: "Achternaam",
    legalName: "Wettelijke naam",
    email_address: "E-mailadres",
    phone_number: "Telefoonnummer",
    social_registration_number: "BSN",
  },
  main_menu: {
    dashboard: "Overzicht",
    clients: "Cliënten",
    colleagues: "Collega's",
    planning: "Planning",
    reporting: "Rapportages",
    invoices: "Facturen",
    payroll_items: "Loongegevens",
    configuration: "Instellingen",
  },
  dashboard: {
    planning: {
      title: "Mijn Planning",
    },
    missing_reports: {
      title: "Missende Rapportages",
      in_development: "Onder ontwikkeling",
    },
    reports: {
      title: "Recente Rapportages",
      not_found: "Geen rapportages gevonden",
      show_all: "Toon alles",
      dialog_title: "Alle Rapportages",
    },
    clients: {
      title: "Mijn Cliënten",
    },
  },
  clients: {
    list: {
      add: "Cliënt Toevoegen",
      current: "Actief",
      archived: "Gearchiveerd",
    },
    show: {
      client: "Cliënt",
      planning: "Planning",
      reports: "Rapportages",
      team: "Team",
      invoices: "Facturen",
    },
    custom_pricing: {
      title: "Prijsafspraken",
      add: "Prijsafspraak toevoegen",
      custom_price: "Prijsafspraak",
    },
    edit: {
      personal_information: "Persoonsgegevens Cliënt",
      company_information: "Bedrijfsgegevens"
    },
  },
  employees: {
    list: {
      add: "Medewerker Toevoegen",
      current: "Actief",
      archived: "Gearchiveerd",
    },
    show: {
      employee: "Medewerker",
      planning: "Planning",
      reports: "Rapportages",
      clients: "Cliënten",
      payroll: "Loongegevens",
      contract: "Contract",
    },
    edit: {
      personal_information: "Persoonsgegevens Medewerker",
      contract_details: "Contractgegevens",
    },
  },
  planning: {
    add: {
      client: "Cliënt",
      employees: "Medewerker(s)",
      service: "Dienst",
    },
    representation: {
      for: "voor",
      at: "om",
      from: "van",
      to: "tot",
    },
  },
  reporting: {
    add: "Rapportage Toevoegen",
    start: "Begin",
    end: "Eind",
    service: "Dienst",
    invoices: "Facturen",
    representation: {
      reported_by: "gerapporteerd door",
    },
    form: {
      title: "Dienst Rapportage",
      relationships: "Koppelingen",
      hours: "uur",
      minutes: "minuten",
      help: {
        start: "Datum en tijd waarop je dienst begon",
        end: "Datum en tijd waarop je dienst eindigde",
        comments: "Beschrijf wat er is gebeurd en wat je hebt gesignaleerd tijdens je dienst",
        enter_dates: "Voer eerst de start- en eindtijd in",
        customer: "De cliënt waar je over rapporteert",
        event_type: "De dienst waar je over rapporteert",
        owner: "De medewerker die de rapportage indient",
      },
    },
  },
  invoices: {
    invoiceNo: 'Factuur',
    period: 'Factuurperiode',
    totalMinutes: 'Minuten',
    totalHours: 'Urentotaal',
    totalVAT: 'Totaal BTW',
    totalDue: 'Totaal te innen',
    totalDays: 'Dagen',
    list: {
      hours: 'Uren',
      minutes: 'Minuten',
      description: 'Beschrijving',
      hourlyRate: 'Uurtarief',
      VAT: 'BTW%',
      total: 'Subtotaal',
    }
  },
  payroll: {
    period: 'Salarisperiode',
    grossTotal: 'Totaal bruto',
    list: {
      date: "Datum",
      flatFee: "Vast uurtarief",
      ORT: "ORT%",
    },
  },
  configuration: {
    menu: {
      services: "Diensten & Facturatie",
    },
    services: {
      add: "Dienst Toevoegen",
      title: "Dienst",
      hourly_rate: "Uurtarief",
      VAT: "BTW%",
      billable: "Facturabel",
      global: "Globaal",
      on_call: "Aanwezigheidsdienst",
      on_call_active: {
        title: "Aanwezig & Actief",
        start_day: "Start dag",
        start_time: "Start tijd",
        end_day: "Eind dag",
        end_time: "Eind tijd",
      },
      range_rates: {
        title: "Tarieven per periode",
        range: "Periode",
        rate: "Effectief tarief",
      },
      template_invoice_lines: {
        title: "Factuurregels",
        share: "Aandeel",
        template: "Patroon",
      },
      form: {
        details: "Details",
        billing_details: "Facturatie details",
        name: "Naam",
        tag: "Tag",
        on_call: {
          first: "Eerste",
          last: "Laatste",
        },
        range_rates: {
          week_day: "Weekdag",
          begin_time: "Begin tijd",
          end_time: "Eind tijd",
          rate: "Effectief tarief",
          days: {
            mon: "Maandag",
            tue: "Dinsdag",
            wed: "Woensdag",
            thu: "Donderdag",
            fri: "Vrijdag",
            sat: "Zaterdag",
            sun: "Zondag",
          },
        },
        relationships: {
          title: "Koppelingen",
          customers: "Cliënten",
        },
        help: {
          name: "De naam van de dienst",
          tag: "De tag wordt gebruikt voor interne referenties",
          hourly_rate: "De gefactureerde vergoeding per uur",
          VAT: "Het BTW percentage",
          on_call: {
            start_day: "Welke dag de periode start",
            start_time: "Welk tijdstip de periode start",
            end_day: "Welke dag de periode eindigt",
            end_time: "Welk tijdstip de periode eindigt",
          },
          range_rates: {
            week_day: "De dag waarop het tarief geldt",
            begin_time: "Vanaf wanneer het tarief geldt",
            end_time: "Tot wanneer het tarief geldt",
            rate: "Het effectieve tarief voor de opgegeven periode",
          },
          template_invoice_lines: {
            share:
              "Percentage van gerapporteerde tijd die verschijnt op de factuurregel",
            template: "Patroon die de factuurregel volgt",
          },
          relationships: {
            customers: "De cliënten waar deze dienst geldt",
          },
        },
      },
    },
  },
});

export { Dutch };
