import * as React from "react";
import {
  TopToolbar,
  // SortButton,
  FilterButton,
  ExportButton,
  useGetIdentity,
  LinearProgress,
} from "react-admin";

const PlannedEventListActions = ({ showFilter = true, showExport = true }) => {
  const { data: identity, isLoading } = useGetIdentity();

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <TopToolbar>
      {showFilter && <FilterButton />}
      {showExport &&
        identity.roles.some((e) =>
          ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
        ) && (
          <React.Fragment>
            <ExportButton />
          </React.Fragment>
        )}
    </TopToolbar>
  );
};

export { PlannedEventListActions };
