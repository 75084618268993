import * as React from "react";
// import { makeStyles } from "@mui/styles";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import { Person, Business, Block, WorkOff } from "@mui/icons-material";
import {
  List as RaList,
  Link,
  SimpleListLoading,
  useListContext,
  useGetIdentity,
  useTranslate,
} from "react-admin";

import { CustomerListFilter } from "./CustomerListFilter";
import { CustomerListActions } from "./CustomerListActions";
import { TabPanel } from "../../Components";

const CustomerListContent = () => {
  const { data, isLoading } = useListContext();

  if (isLoading || data === undefined) {
    return (
      <SimpleListLoading
        hasLeftAvatarOrIcon
        hasSecondaryText
        hasTertiaryText
        nbFakeLines={5}
      />
    );
  }

  return (
    <List>
      {data.map((customer) => {
        const id = customer["id"];
        const eid = encodeURIComponent(id);
        return customer.isActive ? (
          <React.Fragment>
            <ListItem
              key={id}
              component={Link}
              to={`/customers/${eid}/show`}
            >
              <ListItemAvatar>
                <Avatar>
                  {customer.isCompany ? <Business /> : <Person />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={customer.isCompany ? customer.name : `${customer.name.charAt(0)}. ${customer.lastName}`}
                secondary={`${customer.phoneNumber} | ${customer.emailAddress}`}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ) : null;
      })}
    </List>
  );
};

const InactiveCustomerListContent = () => {
  const { data, isLoading } = useListContext();

  if (isLoading || data === undefined) {
    return (
      <SimpleListLoading
        hasLeftAvatarOrIcon
        hasSecondaryText
        hasTertiaryText
        nbFakeLines={5}
      />
    );
  }

  return (
    <List>
      {data.map((customer) => {
        const id = customer["id"];
        const eid = encodeURIComponent(id);
        return customer.isActive ? null : (
          <React.Fragment>
            <ListItem
              key={id}
              component={Link}
              to={`/customers/${eid}/show`}
            >
              <ListItemAvatar>
                <Avatar>{customer.isCompany ? <WorkOff /> : <Block />}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={customer.isCompany ? customer.name : `${customer.name.charAt(0)}. ${customer.lastName}`}
                secondary={`${customer.phoneNumber} | ${customer.emailAddress}`}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        );
      })}
    </List>
  );
};

const CustomerList = ({
  filter = null,
  filters = null,
  actions = <CustomerListActions />,
  aside = <CustomerListFilter />,
  tabbed = true,
}) => {
  const { data: identity } = useGetIdentity();
  const [tabstate, setValue] = React.useState(0);
  const changeTab = (event, newValue) => {
    setValue(newValue);
  };
  const translate = useTranslate();

  return identity ? (
    <RaList
      empty={false}
      filter={filter}
      filters={filters}
      actions={actions}
      aside={aside}
      perPage={null}
      pagination={false}
      sx={{
        marginTop: 1,
      }}
      // filterDefaultValues={{ employees: identity?.id }}
      // sort={{ field: 'createdAt', order: 'DESC' }}
    >
      {tabbed && identity.roles.some((e) =>
        ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
      ) ? (
        <React.Fragment>
          <Tabs
            value={tabstate}
            textColor="primary"
            indicatorColor="primary"
            onChange={changeTab}
            sx={{
              marginLeft: 1,
            }}
          >
            <Tab label={translate("clients.list.current")} />
            <Tab label={translate("clients.list.archived")} />
          </Tabs>
          <Divider />

          <TabPanel value={tabstate} index={0}>
            <CustomerListContent />
          </TabPanel>

          <TabPanel value={tabstate} index={1}>
            <InactiveCustomerListContent />
          </TabPanel>
        </React.Fragment>
      ) : (
        <CustomerListContent />
      )}
    </RaList>
  ) : null;
};

export { CustomerList };
