import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  useGetIdentity,
  useShowContext,
  Loading,
  ReferenceField,
  TextField,
  RichTextField,
  LinearProgress,
  useTranslate,
} from "react-admin";
import { format, formatDistanceStrict } from "date-fns";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  note: {
    marginBottom: theme.spacing(2),
  },
  noteText: {
    backgroundColor: theme.palette.mode === "light" ? "#e6f3e1" : "#616161",
    padding: theme.spacing(1),
    borderRadius: 10,
    color:
      theme.palette.mode === "light" ? "#000 !important" : "#fff !important",
  },
}));

const ListOnCallRanges = ({ record }) => {
  let firstDay =
    typeof record.dateStart === "string"
      ? new Date(record.dateStart)
      : record.dateStart;
  let lastDay =
    typeof record.dateEnd === "string"
      ? new Date(record.dateEnd)
      : record.dateEnd;

  if (record.onCallRanges.length > 0) {
    return (
      <Typography color="textSecondary">
        Active periods:
        <ul>
          {record.onCallRanges.map((item) => {
            let text = ``;
            if (item.startDay === item.endDay) {
              if (item.startDay === "1") {
                text = `${format(firstDay, "dd-MM-yyyy")} ${
                  item.startTime
                } to ${item.endTime}`;
              } else {
                text = `${format(lastDay, "dd-MM-yyyy")} ${item.startTime} to ${
                  item.endTime
                }`;
              }
            } else {
              text = `${format(firstDay, "dd-MM-yyyy")} ${
                item.startTime
              } to ${format(lastDay, "dd-MM-yyyy")} ${item.endTime}`;
            }
            return (
              <React.Fragment>
                <li>{text}</li>
              </React.Fragment>
            );
          })}
        </ul>
      </Typography>
    );
  }

  return null;
};

/**
 * Main content area
 */
const EventReportShowContent = ({
  record,
  showFor = true,
  showBy = true,
  showCreatedUpdated = true,
}) => {
  const translate = useTranslate();
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const classes = useStyles();
  const { record: crecord, isLoading: crecordLoading } = useShowContext();

  if (identityLoading) {
    return <LinearProgress />;
  }

  if (!record && crecordLoading) {
    return <Loading />;
  }

  if (!record) {
    record = crecord;
  }

  const oid = record.owner.split("/")[3];
  const startDate = format(new Date(record.dateStart), "E dd LLL yyyy");
  const startTime = format(new Date(record.dateStart), "HH:mm");
  const endDate = format(new Date(record.dateEnd), "E dd LLL yyyy");
  const endTime = format(new Date(record.dateEnd), "HH:mm");
  const createdDate = format(new Date(record.createdAt), "E dd LLL yyyy HH:mm");
  const updatedDate = format(new Date(record.updatedAt), "E dd LLL yyyy HH:mm");

  const startString = `${startDate} ${translate(
    "planning.representation.at"
  )} ${startTime}`;
  const endString =
    startDate === endDate
      ? `${endTime}`
      : `${endDate} ${translate("planning.representation.at")} ${endTime}`;

  return (
    <div className={classes.note}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color="textSecondary">
          <ReferenceField
            record={record}
            source="eventType"
            reference="event_types"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          {record.customer && showFor && (
            <React.Fragment>
              {` ${translate("planning.representation.for")} `}
              <ReferenceField
                record={record}
                source="customer"
                reference="customers"
                link="show"
              >
                <TextField source="fullName" />
              </ReferenceField>
            </React.Fragment>
          )}
          {record.owner && showBy && (
            <React.Fragment>
              {` ${translate("reporting.representation.reported_by")} `}
              <ReferenceField
                record={record}
                source="owner"
                reference="employees"
                link="show"
              >
                <TextField source="fullName" />
              </ReferenceField>
            </React.Fragment>
          )}
        </Typography>
      </Box>
      <Box className={classes.noteText} mt={1} mb={1}>
        <RichTextField record={record} source="comments" />
      </Box>
      <Typography color="textSecondary">
        {`~${formatDistanceStrict(
          new Date(record.dateStart),
          new Date(record.dateEnd)
        )} ${translate(
          "planning.representation.from"
        )} ${startString} ${translate(
          "planning.representation.to"
        )} ${endString}`}
      </Typography>
      <ListOnCallRanges record={record} />
      {showCreatedUpdated &&
        (oid === identity.id ||
          identity.roles.some((e) =>
            ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
          )) && (
          <Typography color="textSecondary">
            {`Created: ${createdDate}, Updated: ${updatedDate}`}
          </Typography>
        )}
    </div>
  );
};

const EventReportShow = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();

  if (identityLoading) {
    return <Loading />;
  }

  return identity ? (
    <Show hasEdit>
      <SimpleShowLayout>
        <EventReportShowContent />
      </SimpleShowLayout>
    </Show>
  ) : null;
};

export { EventReportShow, EventReportShowContent };
