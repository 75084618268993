function timeConvert(n) {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);

  if (rminutes > 0) {
    return [rhours, String(rminutes).padStart(2, "0")];
  }

  return [rhours, "00"];
}

export { timeConvert };
