import inMemoryJWT from "./inMemoryJWT";
import decodeJwt from "jwt-decode";

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(process.env.REACT_APP_TOKEN_AUTH_URL, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "include",
    });
    inMemoryJWT.removeImpersonation(false);
    return fetch(request)
      .then(async (response) => {
        if (response.status === 401) {
          const body = await response.json();
          throw new Error(body.message);
        }
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText ?? "ra.auth.sign_in_error");
        }
        return response.json();
      })
      .then(({ token }) => {
        return inMemoryJWT.setToken(token, 300);
      })
      .catch((error) => {
        throw new Error(error.message ?? "ra.notification.http_error");
      });
  },

  logout: () => {
    const request = new Request(process.env.REACT_APP_TOKEN_INVALIDATE_URL, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      credentials: "include",
    });
    inMemoryJWT.eraseToken();
    inMemoryJWT.removeImpersonation(false);
    return fetch(request)
      .then(() => "/login")
      .catch(() => {
        throw new Error("Network error");
      });
  },

  checkAuth: (params) => {
    return inMemoryJWT.waitForTokenRefresh().then(() => {
      return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
    });
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401) {
      inMemoryJWT.eraseToken();
      inMemoryJWT.removeImpersonation(false);
      return Promise.reject(error);
    }
    return Promise.resolve();
  },

  getIdentity: () => {
    return inMemoryJWT.waitForTokenRefresh().then(() => {
      const token = inMemoryJWT.getToken();
      if (token) {
        if (inMemoryJWT.getImpersonation()) {
          const { impersonationId, impersonationUsername, impersonationRoles, impersonationFullName } =
            inMemoryJWT.getImpersonation();

          const impersonatedIdentity = {
            id: impersonationId,
            username: impersonationUsername,
            roles: impersonationRoles,
            fullName: impersonationFullName,
          };
          return impersonatedIdentity;
        }
        const decodedToken = decodeJwt(token);
        const identity = {
          id: decodedToken.id,
          username: decodedToken.username,
          roles: decodedToken.roles,
          fullName: decodedToken.fullName,
        };
        return identity;
      }
    });
  },

  getPermissions: () => {
    return inMemoryJWT.waitForTokenRefresh().then(() => {
      const token = inMemoryJWT.getToken();
      if (token) {
        if (inMemoryJWT.getImpersonation()) {
          const { impersonationRoles } = inMemoryJWT.getImpersonation();
          return impersonationRoles;
        }
        const decodedToken = decodeJwt(token);
        return decodedToken.roles;
      }
    });
  },
};

export { authProvider };
