import { PayrollItemList } from "./PayrollItemList";

const PayrollItems = {
  list: PayrollItemList,
  show: false,
  create: false,
  edit: false,
};

export { PayrollItems };
