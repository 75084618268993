import { InvoiceCollectionList } from "./InvoiceCollectionList";

const InvoiceCollections = {
  list: InvoiceCollectionList,
  show: false,
  create: false,
  edit: false,
};

export { InvoiceCollections };
