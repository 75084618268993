import { Box } from "@mui/material";

const TabPanel = (props) => {
  const { children, value, index, ...restProps } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...restProps}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export { TabPanel };
