import * as React from "react";
import { TextInput, required, useTranslate } from "react-admin";
import { Box, Typography } from "@mui/material";

const Spacer = () => <Box width={20} component="span" />;

const AddressForm = () => {
  const translate = useTranslate();
  return (
    <Box mt={2}>
      <Box mt={2} display="flex" justifyContent="center">
        <Typography>{translate("contact.address")}</Typography>
      </Box>
      <Box display="flex">
        <TextInput
          label="Street name"
          source="address.streetName"
          helperText="The street name."
          validate={[required()]}
          fullWidth
        />
        <Spacer />
        <TextInput
          label="Nr."
          source="address.streetNumber"
          helperText="The building number."
          validate={[required()]}
        />
      </Box>
      <Box display="flex">
        <TextInput
          label="ZIP code"
          source="address.postalCode"
          helperText="The ZIP code."
          validate={[required()]}
        />
        <Spacer />
        <TextInput
          label="City"
          source="address.city"
          helperText="The city."
          validate={[required()]}
          fullWidth
        />
      </Box>
    </Box>
  );
};

export { AddressForm };
