import * as React from "react";
import {
  CreateBase,
  TextInput,
  DateInput,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  Form,
  FormDataConsumer,
  Toolbar,
  required,
  useCreateContext,
  useCreate,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Person, Business } from "@mui/icons-material";
import { AddressForm } from "../../Components";

const Spacer = () => <Box width={20} component="span" />;

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    width: "60px",
    height: "60px",
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
  formCard: {
    width: "100%",
  },
}));

const RepresentativeForm = () => {
  const translate = useTranslate();
  return (
    <>
      <Box mt={2} display="flex" justifyContent="center">
        <Typography>{translate("contact.representative")}</Typography>
      </Box>
      <Box display="flex">
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <>
              <TextInput
                source="representativeName"
                validate={formData.isCompany ? [required()] : null}
                fullWidth
              />
              <Spacer />
              <TextInput
                source="representativeEmailAddress"
                validate={formData.isCompany ? [required()] : null}
                fullWidth
              />
              <Spacer />
              <TextInput source="representativePhoneNumber" fullWidth />
            </>
          )}
        </FormDataConsumer>
      </Box>
    </>
  );
};

const CommonFormContent = () => {
  const translate = useTranslate();
  return (
    <Box>
      <Box display="flex">
        <TextInput
          label="Primary email"
          source="emailAddress"
          helperText="Primary email address."
          validate={[required()]}
          fullWidth
        />
        <Spacer />
        <TextInput
          label="Invoice email"
          source="invoiceEmailAddress"
          helperText="Copies of invoices will be sent here."
          fullWidth
        />
      </Box>
      <Box display="flex">
        <TextInput
          label="Phone number"
          source="phoneNumber"
          helperText="Primary phone number."
          validate={[required()]}
          fullWidth
        />
        <Spacer />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <TextInput
              label={formData.isCompany ? "KVK" : "BSN"}
              source="socialRegistrationNumber"
              helperText="The social registration number."
              validate={[required()]}
              fullWidth
              {...rest}
            />
          )}
        </FormDataConsumer>
      </Box>
      <Box display="flex">
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.isCompany ? null : (
              <DateInput
                source="DOB"
                helperText="Date of birth."
                options={{ format: "dd/MM/yyyy" }}
                validate={[required()]}
                fullWidth
                {...rest}
              />
            )
          }
        </FormDataConsumer>
      </Box>
      <Divider />
      <RepresentativeForm />
      <Divider />
      <AddressForm />
      <Divider />
      <Box mt={2} display="flex" justifyContent="center">
        <Typography>{translate("clients.show.team")}</Typography>
      </Box>
      <Box>
        <ReferenceArrayInput source="employees" reference="employees">
          <SelectArrayInput
            optionText="fullName"
            helperText="The employees on this customer's team."
            translateChoice={false}
            fullWidth
          />
        </ReferenceArrayInput>
      </Box>
    </Box>
  );
};

const CreatePrivateCustomerFormContent = () => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <CardContent>
      <Box>
        <Box display="flex">
          <Box flex={1}></Box>
          <Box flex={1} display="flex" justifyContent="center">
            <Avatar className={classes.avatarContainer}>
              <Person className={classes.avatar} />
            </Avatar>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <BooleanInput label="B2B" source="isCompany" />
          </Box>
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <Typography>
            {translate("clients.edit.personal_information")}
          </Typography>
        </Box>
        <Box display="flex">
          <TextInput
            label="First name"
            source="name"
            helperText="Preferred first name."
            validate={[required()]}
            fullWidth
          />
          <Spacer />
          <TextInput
            label="Last name"
            source="lastName"
            helperText="Legally recognized last name."
            validate={[required()]}
            fullWidth
          />
        </Box>
        <TextInput
          label="Legal name(s)"
          source="legalNames"
          helperText="Legally recognized name(s), if it differs."
          fullWidth
        />
        <CommonFormContent />
      </Box>
    </CardContent>
  );
};

const CreateCompanyCustomerFormContent = () => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <CardContent>
      <Box>
        <Box display="flex">
          <Box flex={1}></Box>
          <Box flex={1} display="flex" justifyContent="center">
            <Avatar className={classes.avatarContainer}>
              <Business className={classes.avatar} />
            </Avatar>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <BooleanInput label="B2B" source="isCompany" />
          </Box>
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <Typography>
            {translate("clients.edit.company_information")}
          </Typography>
        </Box>
        <Box>
          <TextInput
            label="Company name"
            source="name"
            helperText="Registered company name."
            validate={[required()]}
            fullWidth
          />
        </Box>
        <CommonFormContent />
      </Box>
    </CardContent>
  );
};

const CustomerCreateContent = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const { record } = useCreateContext();
  const [create] = useCreate();
  const save = React.useCallback(
    async (values) => {
      try {
        await create(
          "customers",
          { data: values },
          { returnPromise: true }
        ).then((response) => {
          const eid = encodeURIComponent(response.id);
          redirect(`/customers/${eid}/show`);
        });
      } catch (error) {
        let errors = {};
        error.body[0]["http://www.w3.org/ns/hydra/core#description"][0][
          "@value"
        ]
          .split(/\n/)
          .forEach((e) => {
            notify(e, { type: "warning" });
            const err = e.split(": ");
            errors[err[0]] = err[1];
          });
        notify("ra.notification.create.failure", {
          type: "warning",
        });
        return errors;
      }
    },
    [create, notify, redirect]
  );
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: 2,
        width: "100%",
        maxWidth: 800,
        alignSelf: "center",
        "& form": {
          width: "100%",
        },
      }}
    >
      <Form record={record} onSubmit={save}>
        <Card className={classes.formCard}>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              (formData.isCompany && (
                <CreateCompanyCustomerFormContent {...rest} />
              )) || <CreatePrivateCustomerFormContent {...rest} />
            }
          </FormDataConsumer>
          <Toolbar />
        </Card>
      </Form>
    </Box>
  );
};

const CustomerCreate = () => {
  return (
    <CreateBase
      transform={(data) => ({
        ...data,
      })}
    >
      <CustomerCreateContent />
    </CreateBase>
  );
};

export { CustomerCreate };
