import React from "react";
import { Layout, CheckForApplicationUpdate } from "react-admin";
import { makeStyles } from "@mui/styles";
import { CustomHeader } from "./Header";
import { ReactQueryDevtools } from "react-query/devtools";

/**
 * Global Left Sidebar
 */
const CustomSidebar = () => {
  return null;
  // return <Sidebar />;
};

/**
 * Global Layout Styles
 */
const useCustomStyles = makeStyles((theme) => ({
  appFrame: {
    marginTop: "unset",
    maxWidth: "100vw",
  },
  content: {
    maxWidth: "100vw",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "24px",
    },
  },
}));

/**
 * Global Layout
 */
const CustomLayout = ({ children, ...props }) => {
  const classes = useCustomStyles();
  return (
    <React.Fragment>
      <Layout
        classes={classes}
        sidebar={CustomSidebar}
        appBar={CustomHeader}
        {...props}
      >
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
        <CheckForApplicationUpdate />
      </Layout>
    </React.Fragment>
  );
};

export { CustomLayout };
