import * as React from "react";
import { Box, Card, CardContent, Tab } from "@mui/material";
import {
  Loading,
  ResourceContextProvider,
  useGetIdentity,
  useTranslate,
} from "react-admin";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSearchParams } from "react-router-dom";
import { EventTypeList } from "../../Resources/EventTypes/EventTypeList";

const slug_to_index = ({index = null, slug = null}) => {
  const mapping = {
    0: "services",
    1: "dummy",
  };

  // If we're passed an index, return the corresponding slug
  if (index !== null) {
    return String(mapping[index]);
  }

  // If we're passed a slug, return the corresponding index
  return Number(Object.keys(mapping).find(key => mapping[key] === slug));
};

const Configuration = ({ defaultTab = "services" }) => {
  const { isLoading: identityLoading } = useGetIdentity();
  const translate = useTranslate();

  const [searchParams, setSearchParams] = useSearchParams({});
  const renderTab = searchParams.get("tab") ?? defaultTab;
  const [value, setValue] = React.useState(slug_to_index({slug: renderTab}));

  React.useEffect(() => {
    if (value !== slug_to_index({slug: renderTab})) {
      setValue(slug_to_index({slug: renderTab}));
    }
    if (searchParams['tab'] !== renderTab) {
      setSearchParams({ tab: renderTab });
    }
  }, [value, renderTab, searchParams, setValue, setSearchParams]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ tab: slug_to_index({index: newValue}) });
  };

  if (identityLoading) {
    return <Loading />;
  }

  return (
    <TabContext value={value}>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            flex: 1,
            marginLeft: "1em",
            marginRight: "1em",
            marginTop: "4.45em",
          }}
        >
          <Card sx={{ minHeight: "30em" }}>
            <CardContent>
              <TabList
                orientation="vertical"
                value={value}
                onChange={handleChange}
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                <Tab label={translate("configuration.menu.services")} />
                <Tab label="Dummy" />
              </TabList>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ flex: 5.0, marginRight: "1em" }}>
          <TabPanel value={0}>
            <ResourceContextProvider value="event_types">
              <EventTypeList />
            </ResourceContextProvider>
          </TabPanel>
          <TabPanel value={1}>
            <Card sx={{ marginTop: "3em", minHeight: "15em" }}>
              <CardContent>Dummy Configuration Page</CardContent>
            </Card>
          </TabPanel>
        </Box>
      </Box>
    </TabContext>
  );
};

export { Configuration };
