import * as React from "react";
import { makeStyles } from "@mui/styles";
import {
  TopToolbar,
  // FilterButton,
  ExportButton,
  CreateButton,
  useGetIdentity,
  LinearProgress,
  useTranslate,
} from "react-admin";

const useEventTypeListActionsStyles = makeStyles((theme) => ({
  createButton: {
    marginLeft: theme.spacing(2),
  },
}));

const EventTypeListActions = () => {
  const { data: identity, isLoading } = useGetIdentity();
  const classes = useEventTypeListActionsStyles();
  const translate = useTranslate();

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <TopToolbar>
      {/* <FilterButton /> */}
      {identity.roles.some((e) =>
        ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
      ) && (
        <React.Fragment>
          <ExportButton />
          <CreateButton
            className={classes.createButton}
            basePath="/event_types"
            variant="contained"
            label={translate("configuration.services.add")}
          />
        </React.Fragment>
      )}
    </TopToolbar>
  );
};

export { EventTypeListActions };
