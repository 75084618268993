import * as React from "react";
import { makeStyles } from "@mui/styles";
import {
  TopToolbar,
  // SortButton,
  ExportButton,
  CreateButton,
  useGetIdentity,
  LinearProgress,
  useTranslate,
} from "react-admin";

const useCustomerListActionsStyles = makeStyles((theme) => ({
  createButton: {
    marginLeft: theme.spacing(2),
  },
}));

const CustomerListActions = () => {
  const { data: identity, isLoading } = useGetIdentity();
  const classes = useCustomerListActionsStyles();
  const translate = useTranslate();

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <TopToolbar>
      {/*<SortButton fields={["fullName", "createdAt"]} />*/}
      {identity.roles.some((e) =>
        ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)
      ) && (
        <React.Fragment>
          <ExportButton />
          <CreateButton
            className={classes.createButton}
            basePath="/customers"
            variant="contained"
            label={translate("clients.list.add")}
          />
        </React.Fragment>
      )}
    </TopToolbar>
  );
};

export { CustomerListActions };
