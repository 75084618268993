import inMemoryJWT from "./inMemoryJWT";
import {
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";

const entrypoint = process.env.REACT_APP_API_URL;
const getHeaders = () => {
  const headers = {};
  if (inMemoryJWT.getToken()) {
    headers["Authorization"] = `Bearer ${inMemoryJWT.getToken()}`;
  }
  if (inMemoryJWT.getImpersonation()) {
    const { impersonationUsername } = inMemoryJWT.getImpersonation();
    headers["X-Impersonate-User"] = impersonationUsername;
  }
  return headers;
};
const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });
const apiDocumentationParser = (setRedirectToLogin) => async () => {
  try {
    setRedirectToLogin(false);

    if (!inMemoryJWT.getToken()) {
      await inMemoryJWT.getRefreshedToken();
    }

    return await parseHydraDocumentation(entrypoint, { headers: getHeaders });
  } catch (result) {
    const { api, response, status } = result;
    if (status !== 401 || !response) {
      throw result;
    }

    // Prevent infinite loop if the token is expired
    inMemoryJWT.eraseToken();
    setRedirectToLogin(true);

    return {
      api,
      response,
      status,
    };
  }
};
const dataProvider = (setRedirectToLogin) => baseHydraDataProvider({
  entrypoint,
  httpClient: fetchHydra,
  apiDocumentationParser: apiDocumentationParser(setRedirectToLogin),
  // mercure: {
  //   hub: process.env.REACT_APP_MERCURE_URL,
  //   jwt: process.env.REACT_APP_MERCURE_TOKEN,
  // },
});

export { dataProvider };
