import React, { useState } from "react";
import { EditingState, ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Appointments,
  AllDayPanel,
  AppointmentForm,
  AppointmentTooltip,
  ViewSwitcher,
  Toolbar,
  EditRecurrenceMenu,
  DayView,
  MonthView,
  ConfirmationDialog,
  DateNavigator,
  TodayButton,
  Resources,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
  Loading,
  LinearProgress,
  useDataProvider,
  useGetList,
  useNotify,
  useGetIdentity,
  useLocaleState,
  useTranslate,
} from "react-admin";
import { Appointment } from "./Appointment";
import { CurrentTimeIndicator } from "./CurrentTimeIndicator";
import { WeekView } from "./Views";
import { EventDataTransformer } from "../../Tools";

/**
 * Declare a global variable to contain all (unfiltered)
 * resource instances.
 */
let globalResourceInstances = {};

const GetCustomerInstances = (filter = {}) => {
  const baseFilter = { pagination: false };
  const { data, isLoading } = useGetList("customers", {
    pagination: { pagination: false },
    filter: { ...baseFilter, ...filter },
  });
  const translate = useTranslate();

  if (isLoading || data === undefined) {
    return {
      instances: [{ id: undefined, text: translate("messages.none"), color: { 400: "#fff" } }],
      isLoading: true,
    };
  }

  const instances = data.map((customer) => {
    return {
      id: customer["id"],
      text: customer["fullName"],
    };
  });

  return { instances, isLoading: false };
};

const GetEmployeeInstances = (filter = {}) => {
  const baseFilter = { pagination: false };
  const { data, isLoading } = useGetList("employees", {
    pagination: { pagination: false },
    filter: { ...baseFilter, ...filter },
  });
  const translate = useTranslate();

  if (isLoading || data === undefined) {
    return {
      instances: [{ id: undefined, text: translate("messages.none"), color: { 400: "#fff" } }],
      isLoading: true,
    };
  }

  const instances = data.map((employee) => {
    return {
      id: employee["id"],
      text: employee["fullName"],
    };
  });

  return { instances, isLoading: false };
};

const GetEventTypeInstances = (filter = {}) => {
  const baseFilter = { pagination: false };
  const { data, isLoading } = useGetList("event_types", {
    pagination: { pagination: false },
    filter: { ...baseFilter, ...filter },
  });
  const translate = useTranslate();

  if (isLoading || data === undefined) {
    return {
      instances: [{ id: undefined, text: translate("messages.none"), color: { 400: "#fff" } }],
      isLoading: true,
    };
  }

  const instances = data.map((eventType) => {
    return {
      id: eventType["id"],
      text: eventType["name"],
    };
  });

  return { instances, isLoading: false };
};

const GetResources = () => {
  const { instances: customerInstances, isLoading: customersLoading } =
    GetCustomerInstances({ isActive: true });
  const { instances: employeeInstances, isLoading: employeesLoading } =
    GetEmployeeInstances({ isActive: true });
  const { instances: eventTypeInstances, isLoading: eventTypesLoading } =
    GetEventTypeInstances();
  const translate = useTranslate();

  if (customersLoading || employeesLoading || eventTypesLoading) {
    return { resources: [], isLoading: true };
  }

  return {
    resources: [
      {
        id: "customer",
        title: translate("planning.add.client"),
        fieldName: "customer",
        instances: customerInstances,
        allowMultiple: false,
      },
      {
        id: "employees",
        title: translate("planning.add.employees"),
        fieldName: "employees",
        instances: employeeInstances,
        allowMultiple: true,
      },
      {
        id: "eventType",
        title: translate("planning.add.service"),
        fieldName: "eventType",
        instances: eventTypeInstances,
        allowMultiple: false,
      },
    ],
    isLoading: false,
  };
};

const validateResourceInstances = (resource, old, change) => {
  /**
   * The first time we're called, the 'old' parameter contains all
   * resource instances without filters applied. Consecutive calls
   * will still pass the 'old' parameter, but it will contain a
   * filtered result.
   */
  if (typeof globalResourceInstances[resource] === "undefined") {
    globalResourceInstances[resource] = old;
  }

  const results = [];

  change.forEach((c) => {
    globalResourceInstances[resource].some((e) => {
      if (c.id === e.id) {
        results.push(e);
      }
      return null;
    });
  });

  return results;
};

const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
  return (
    <AppointmentForm.BasicLayout
      appointmentData={appointmentData}
      onFieldChange={onFieldChange}
      {...restProps}
    ></AppointmentForm.BasicLayout>
  );
};

const LabelComponent = (props) => {
  return <AppointmentForm.Label {...props} />;
};

const DateComponent = (props) => {
  return <AppointmentForm.DateEditor {...props} />;
};

const BoolEditor = (props) => {
  return <AppointmentForm.BooleanEditor {...props} />;
};

const InputComponent = (props) => {
  return <AppointmentForm.TextEditor {...props} />;
};

const ResourceComponent = (props) => {
  const translate = useTranslate();

  // Create model object
  const resourceValues = {
    customer: undefined,
    employees: [],
    eventType: undefined,
  };

  // Add selected values to model object
  props.appointmentResources.map((i) => {
    const key = i.fieldName;
    if (key === "employees") {
      resourceValues[key].push(i.id.split("/")[3]);
    } else {
      resourceValues[key] = i.id.split("/")[3];
    }
    return null;
  });

  let newAppointmentResources = [];

  // Filter Customers based on selected Employees and EventType
  if (props.resource.fieldName === "customer") {
    const filter = { isActive: true };
    if (resourceValues.employees.length !== 0) {
      filter.employees = resourceValues.employees.join(" ");
    }
    if (typeof resourceValues.eventType !== "undefined") {
      filter.eventTypes = resourceValues.eventType;
    }

    // Get filtered results
    const { instances, isLoading } = GetCustomerInstances(filter);

    if (isLoading) {
      return <LinearProgress />;
    } else {
      const newInstances = validateResourceInstances(
        "customer",
        props.resource.instances,
        instances
      );

      // If the currently selected element is not part
      // of the filtered set, unset the selection
      newAppointmentResources = props.appointmentResources.filter((o) => {
        if (o.fieldName !== "customer") {
          return true;
        }
        if (newInstances.some((e) => o.id === e.id)) {
          return true;
        } else {
          return false;
        }
      });

      // Allow deselecting Customer
      newInstances.unshift({
        id: undefined,
        text: translate("messages.none"),
        color: { 400: "#fff" },
      });

      props.resource.instances = newInstances;
    }
  }

  // Filter Employees based on selected Customer
  if (props.resource.fieldName === "employees") {
    const filter = { isActive: true };
    if (typeof resourceValues.customer !== "undefined") {
      filter.customers = resourceValues.customer;
    }
    const { instances, isLoading } = GetEmployeeInstances(filter);
    if (isLoading) {
      return <LinearProgress />;
    } else {
      const newInstances = validateResourceInstances(
        "employees",
        props.resource.instances,
        instances
      );

      // If the currently selected element is not part
      // of the filtered set, unset the selection
      newAppointmentResources = props.appointmentResources.filter((o) => {
        if (o.fieldName !== "employees") {
          return true;
        }
        if (newInstances.some((e) => o.id === e.id)) {
          return true;
        } else {
          return false;
        }
      });

      props.resource.instances = newInstances;
    }
  }

  // Filter EventTypes based on selected Customer
  if (props.resource.fieldName === "eventType") {
    const filter = {};
    if (typeof resourceValues.customer !== "undefined") {
      filter.customers = resourceValues.customer;
      filter.billable = true;
    } else {
      filter.billable = false;
    }

    // Get filtered results
    const { instances, isLoading } = GetEventTypeInstances(filter);

    if (isLoading) {
      return <LinearProgress />;
    } else {
      const newInstances = validateResourceInstances(
        "eventType",
        props.resource.instances,
        instances
      );

      // If the currently selected element is not part
      // of the filtered set, unset the selection
      newAppointmentResources = props.appointmentResources.filter((o) => {
        if (o.fieldName !== "eventType") {
          return true;
        }
        if (newInstances.some((e) => o.id === e.id)) {
          return true;
        } else {
          return false;
        }
      });

      // Allow deselecting EventType
      newInstances.unshift({
        id: undefined,
        text: translate("messages.none"),
        color: { 400: "#fff" },
      });

      props.resource.instances = newInstances;
    }
  }

  return (
    <AppointmentForm.ResourceEditor
      {...props}
      appointmentResources={newAppointmentResources}
    />
  );
};

const EventScheduler = (props) => {
  const translate = useTranslate();
  const vh =
    Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    ) - 140;

  if (props.defaultView === EventScheduler.MONTH_VIEW) {
    var mh = vh > 700 ? 700 : vh;
  }

  let [height, setHeight] = useState(mh ?? vh);
  let [data, setData] = useState(props.appointments);
  if (props.appointments.length !== data.length) {
    setData(props.appointments)
  }

  const dataProvider = useDataProvider();
  const [locale] = useLocaleState();
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { resources, isLoading: resourcesLoading } = GetResources();
  const notify = useNotify();

  if (identityLoading || resourcesLoading) {
    return <Loading />;
  }

  let canEditEvents = false;
  if (props.allowEdits) {
    identity.roles.some((e) => ["ROLE_ADMIN", "ROLE_MANAGER"].includes(e)) &&
      (canEditEvents = true);
  }

  return (
    <Scheduler
      firstDayOfWeek={0}
      height={props.height ?? height}
      data={data}
      locale={locale}
      {...props}
    >
      <ViewState
        defaultCurrentViewName={props.defaultView}
        onCurrentViewNameChange={function (view) {
          if (view === EventScheduler.MONTH_VIEW) {
            mh > 700 ? setHeight(700) : setHeight(mh);
          } else {
            setHeight(vh);
          }
        }}
      />
      {canEditEvents && (
        <EditingState
          onCommitChanges={({ added, changed, deleted }) => {
            if (added) {
              // Instantly show the new appointment
              let newdata = [...data, { id: "new", ...added }];
              setData(newdata);
              dataProvider
                .create("planned_events", {
                  data: EventDataTransformer.fromEventScheduler(added),
                })
                .then(
                  // on fulfilled
                  (response) => {
                    const event = EventDataTransformer.fromPlannedEvent(
                      response.data
                    );
                    // Replace the appointment with the returned object
                    setData(
                      newdata.map((appointment) =>
                        appointment.id === "new" ? event : appointment
                      )
                    );

                    notify("ra.notification.planning.create.success", {
                      type: "success",
                    });
                  },

                  // on rejected
                  (err) => {
                    // Remove the failed event from the view
                    setData(
                      newdata.filter((appointment) => appointment.id !== "new")
                    );
                    notify("ra.notification.planning.create.failure", {
                      type: "warning",
                      messageArgs: { err: err },
                    });
                  }
                );
            }
            if (changed) {
              // Instantly change the view
              let olddata = data;
              let newdata = data.map((appointment) =>
                changed[appointment.id]
                  ? { ...appointment, ...changed[appointment.id] }
                  : appointment
              );
              setData(newdata);
              const id = Object.keys(changed);
              const change = changed[id];
              dataProvider
                .update("planned_events", {
                  id: id,
                  data: EventDataTransformer.fromEventScheduler(change),
                })
                .then(
                  // on fulfilled
                  (response) => {
                    let respdata = response.data;
                    let event = EventDataTransformer.fromPlannedEvent(respdata);
                    // Replace the appointment with the returned object
                    setData(
                      newdata.map((appointment) =>
                        appointment.id === respdata.id ? event : appointment
                      )
                    );
                    notify("ra.notification.planning.update.success", {
                      type: "success",
                    });
                  },
                  // on rejected
                  (err) => {
                    // revert the view
                    setData(olddata);
                    notify("ra.notification.planning.update.failure", {
                      type: "warning",
                      messageArgs: { err: err },
                    });
                  }
                );
            }
            if (deleted !== undefined) {
              setData(data.filter((appointment) => appointment.id !== deleted));
              dataProvider.delete("planned_events", {
                id: deleted,
              });
            }
          }}
        />
      )}
      {props.showToolbar && <Toolbar />}
      {props.showToolbar && <DateNavigator />}
      {props.showToolbar && <TodayButton messages={{ today: translate("messages.today")}} />}
      {props.showToolbar && <ViewSwitcher  />}
      <DayView cellDuration={60} displayName={translate("messages.day")} />
      <WeekView cellDuration={60} displayName={translate("messages.week")} />
      <MonthView displayName={translate("messages.month")} />
      <AllDayPanel />
      <Appointments appointmentComponent={Appointment} />
      {canEditEvents && <EditRecurrenceMenu />}
      {canEditEvents && <ConfirmationDialog />}
      <CurrentTimeIndicator />
      <AppointmentTooltip
        showCloseButton={true}
        showOpenButton={canEditEvents}
        showDeleteButton={canEditEvents}
      />
      {canEditEvents && (
        <AppointmentForm
          basicLayoutComponent={BasicLayout}
          labelComponent={LabelComponent}
          dateEditorComponent={DateComponent}
          booleanEditorComponent={BoolEditor}
          textEditorComponent={InputComponent}
          resourceEditorComponent={ResourceComponent}
        />
      )}
      <Resources data={resources} mainResourceName={props.mainResourceName} />
    </Scheduler>
  );
};

EventScheduler.DAY_VIEW = "Day";
EventScheduler.WEEK_VIEW = "Week";
EventScheduler.MONTH_VIEW = "Month";

EventScheduler.defaultProps = {
  allowEdits: true,
  showToolbar: true,
  appointments: [],
  defaultView: EventScheduler.WEEK_VIEW,
};

export { EventScheduler };
