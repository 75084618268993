import * as React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  List as RaList,
  FunctionField,
  ReferenceField,
  TextField,
  useListContext,
  useGetIdentity,
  Datagrid,
  EditButton,
  ShowButton,
  ReferenceInput,
  SelectInput,
  DateField,
  Pagination,
  useLocaleState,
  useRecordContext,
  useTranslate,
} from "react-admin";

import { InvoiceListActions } from "./InvoiceListActions";
import { timeConvert } from "../../Tools";

const useStyles = makeStyles((theme) => ({
  ii: {
    "& .column-hrs": {
      width: "4ch",
      maxWidth: "4ch",
    },
    "& .column-minutes": {
      width: "4ch",
      maxWidth: "4ch",
    },
    "& .column-hourlyRate": {
      width: "4ch",
      maxWidth: "4ch",
    },
    "& .column-VAT": {
      width: "4ch",
      maxWidth: "4ch",
    },
    "& .column-total": {
      width: "12ch",
      maxWidth: "12ch",
    },
  },
}));

const InvoiceListContentExpand = () => {
  const record = useRecordContext();
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Box display="flex" justifyContent="center">
      <Paper>
        <TableContainer className={classes.ii}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{translate("invoices.list.hours")}</TableCell>
                <TableCell>{translate("invoices.list.minutes")}</TableCell>
                <TableCell>{translate("invoices.list.description")}</TableCell>
                <TableCell>{translate("invoices.list.hourlyRate")}</TableCell>
                <TableCell>{translate("invoices.list.VAT")}</TableCell>
                <TableCell>{translate("invoices.list.total")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record.invoiceItems.map((o) => (
                <TableRow>
                  <TableCell>{timeConvert(o.minutes)[0]}</TableCell>
                  <TableCell>{timeConvert(o.minutes)[1]}</TableCell>
                  <TableCell>{o.description}</TableCell>
                  <TableCell>
                    {parseFloat(o.hourlyRate).toLocaleString("nl-NL", {
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell>{o.VAT ? `${o.VAT}` : null}</TableCell>
                  <TableCell>
                    {parseFloat(o.total).toLocaleString("nl-NL", {
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

const InvoiceListContent = ({ showCustomer = true, allowSelect = true }) => {
  const [locale] = useLocaleState();
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { isLoading } = useListContext();

  if (isLoading || identityLoading) {
    return null;
  }

  return identity ? (
    <Datagrid
      rowClick="expand"
      expand={<InvoiceListContentExpand />}
      expandSingle
      isRowSelectable={() => allowSelect}
    >
      <TextField source={"invoiceNo"} label={"invoices.invoiceNo"} />
      {showCustomer && (
        <ReferenceField label={"clients.show.client"} reference="customers" source={"customer"}>
          <TextField source={"fullName"} />
        </ReferenceField>
      )}
      <TextField label={"invoices.period"} source={"invoicePeriod"} />
      <FunctionField
        source={"totalMinutes"}
        label={"invoices.totalHours"}
        render={(record) => {
          return `${timeConvert(record.totalMinutes)[0]}:${
            timeConvert(record.totalMinutes)[1]
          }`;
        }}
      />
      <FunctionField
        source={"totalVAT"}
        label={"invoices.totalVAT"}
        render={(record) => {
          return parseFloat(record.totalVAT).toLocaleString("nl-NL", {
            style: "currency",
            currency: "EUR",
          });
        }}
      />
      <FunctionField
        source={"totalDue"}
        label={"invoices.totalDue"}
        render={(record) => {
          return parseFloat(record.totalDue).toLocaleString("nl-NL", {
            style: "currency",
            currency: "EUR",
          });
        }}
      />
      <FunctionField
        source={"isSent"}
        label={"Status"}
        render={(record) => {
          if (record.isSent && record.isPaid) {
            return "Paid";
          } else if (record.isSent) {
            return "Sent";
          } else {
            return "Not Sent";
          }
        }}
      />
      <DateField
        source="updatedAt"
        locales={locale}
        showTime={true}
        options={{
          dateStyle: "medium",
          timeStyle: "short",
          hour12: false,
        }}
      />
      <ShowButton
        onClick={(e) => {
          e.preventDefault();
        }}
      />
      <EditButton />
    </Datagrid>
  ) : null;
};

const InvoiceList = ({
  showCustomer = true,
  filter = null,
  filters = [
    <ReferenceInput label="Customer" source="customer" reference="customers">
      <SelectInput optionText="fullName" />
    </ReferenceInput>,
  ],
  actions = <InvoiceListActions />,
  allowSelect = true,
}) => {
  const { data: identity } = useGetIdentity();
  return identity ? (
    <RaList
      filter={filter}
      filters={filters}
      empty={false}
      actions={actions}
      perPage={15}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 15, 30, 60, 100]} />}
      sort={{ field: "invoiceNo", order: "DESC" }}
    >
      <InvoiceListContent
        showCustomer={showCustomer}
        allowSelect={allowSelect}
      />
    </RaList>
  ) : null;
};

export { InvoiceList, InvoiceListContent };
