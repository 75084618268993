import { defaultTheme } from "react-admin";
import { createTheme } from "@mui/material";
import merge from "lodash/merge";

const CommonTheme = merge({}, defaultTheme, {
  overrides: {
    RaDatagrid: {
      headerCell: {
        fontWeight: "bold",
      },
    },
  },
  palette: {
    appBar: "#5f4090",
    primary: {
      light: "#7d6ca8",
      main: "#5f4090",
      dark: "#630094",
      contrastText: "#fff",
    },
    secondary: {
      light: "#84c466",
      main: "#57ae36",
      dark: "#087833",
      contrastText: "#000",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const LightTheme = createTheme(
  merge({}, CommonTheme, {
    palette: {
      mode: "light",
      background: {
        default: "#fafafb",
      },
    },
  })
);

const DarkTheme = createTheme(
  merge({}, CommonTheme, {
    palette: {
      mode: "dark",
      background: {
        default: "#303030",
      },
      primary: {
        light: "#84c466",
        main: "#57ae36",
        dark: "#087833",
      },
      secondary: {
        light: "#7d6ca8",
        main: "#5f4090",
        dark: "#630094",
      },
    },
  })
);

export { LightTheme, DarkTheme };
