import * as React from "react";
import { Box, Paper } from "@mui/material";
import {
  List as RaList,
  FunctionField,
  TextField,
  useListContext,
  useGetIdentity,
  Datagrid,
  ShowButton,
  Pagination,
  ReferenceArrayField,
} from "react-admin";

import { PayrollCollectionListActions } from "./PayrollCollectionListActions";
import { timeConvert } from "../../Tools";
import { PayrollItemListContent } from "../PayrollItems/PayrollItemList";

const PayrollCollectionListContentExpand = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Paper>
        <ReferenceArrayField reference="payroll_items" source={"payrollItems"}>
          <PayrollItemListContent />
        </ReferenceArrayField>
      </Paper>
    </Box>
  );
};

const PayrollCollectionListContent = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const { isLoading } = useListContext();

  if (isLoading || identityLoading) {
    return null;
  }

  return identity ? (
    <Datagrid
      isRowSelectable={false}
      rowClick="expand"
      expand={<PayrollCollectionListContentExpand />}
      expandSingle
    >
      <TextField source={"payrollPeriod"} label={"payroll.period"} />
      <FunctionField
        source={"totalDays"}
        label={"invoices.totalDays"}
        render={(record) => {
          return parseFloat(record.totalDays).toLocaleString("nl-NL");
        }}
      />
      <FunctionField
        source={"totalMinutes"}
        label={"invoices.totalHours"}
        render={(record) => {
          return `${timeConvert(record.totalMinutes)[0]}:${
            timeConvert(record.totalMinutes)[1]
          }`;
        }}
      />
      <FunctionField
        source={"grossTotal"}
        label={"payroll.grossTotal"}
        render={(record) => {
          return record.grossTotal
            ? parseFloat(record.grossTotal).toLocaleString("nl-NL", {
                style: "currency",
                currency: "EUR",
              })
            : null;
        }}
      />
      {/* <FunctionField
        source={"costTotal"}
        label={"Total cost"}
        render={(record) => {
          return record.costTotal
            ? parseFloat(record.costTotal).toLocaleString("nl-NL", {
                style: "currency",
                currency: "EUR",
              })
            : null;
        }}
      /> */}
      <ShowButton
        onClick={(e) => {
          e.preventDefault();
        }}
      />
    </Datagrid>
  ) : null;
};

const PayrollCollectionList = ({
  filter = null,
  filters = [],
  actions = <PayrollCollectionListActions />,
}) => {
  const { data: identity } = useGetIdentity();
  return identity ? (
    <RaList
      filter={filter}
      filters={filters}
      empty={false}
      actions={actions}
      perPage={15}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 15, 30, 60, 100]} />}
      sort={{ field: "payrollPeriod", order: "DESC" }}
    >
      <PayrollCollectionListContent />
    </RaList>
  ) : null;
};

export { PayrollCollectionList };
