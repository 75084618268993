import { Remove } from "@mui/icons-material";
import { ToggleButton } from "@mui/material";
import {
  AlignmentButtons,
  ClearButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
  RichTextInputToolbar,
  useTiptapEditor,
} from "ra-input-rich-text";

const RichEditorToolbar = ({ size, ...props }) => {
  const editor = useTiptapEditor();

  return (
    <RichTextInputToolbar {...props}>
      <LevelSelect size={size} />
      <FormatButtons size={size} />
      <AlignmentButtons size={size} />
      <ListButtons size={size} />
      <LinkButtons size={size} />
      <QuoteButtons size={size} />
      <ClearButtons size={size} />
      <ToggleButton
        aria-label="Add an horizontal rule"
        title="Add an horizontal rule"
        value="left"
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
        selected={editor && editor.isActive("horizontalRule")}
      >
        <Remove fontSize="inherit" />
      </ToggleButton>
    </RichTextInputToolbar>
  );
};

export { RichEditorToolbar };
