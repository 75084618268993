import polyglotI18nProvider from "ra-i18n-polyglot";
import { English, Dutch } from "../i18n";

// Map locale to language
const messages = {
  en: English,
  nl: Dutch,
};

// return the right translation based on the locale
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "nl");

export { i18nProvider };
