/**
 * Can transform PlannedEvents data from the API
 * into events used by the EventScheduler, and
 * the other way around.
 */
class EventDataTransformer {
  /**
   * Accepts PlannedEvent data from the API and
   * returns event data used by the EventScheduler
   */
  static fromPlannedEvent(input) {
    return {
      id: input.id,
      title: input.name,
      customer: input.customer,
      employees: input.employees ?? [],
      eventType: input.eventType,
      startDate: input.dateStart,
      endDate: input.dateEnd,
      notes: input.description,
      allDay: input.allDay,
      rRule: input.rRule,
      exDate: input.exDate,
    };
  }

  /**
   * Accepts event data from the EventScheduler
   * and returns PlannedEvent data used by the API
   */
  static fromEventScheduler(input) {
    return {
      id: input.id,
      name: input.title,
      customer: input.customer,
      employees: input.employees,
      eventType: input.eventType,
      dateStart: input.startDate,
      dateEnd: input.endDate,
      description: input.notes,
      allDay: input.allDay,
      rRule: input.rRule,
      exDate: input.exDate,
    };
  }
}

export { EventDataTransformer };
